import React, { Component } from "react";
import './index.css';

export default class PwaFooter extends Component {

  render() {

    return (
      <>
       
       
      </>
    )
  }
}