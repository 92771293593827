import React, { Component } from "react";
import { Link } from 'react-router-dom';
import moment from 'moment-jalaali';
import { LangContext } from '../../../App/languageContext';

const CurrentLocale = localStorage.getItem("language");

class CountryCard extends Component {
  render() {
    const props = this.props.data;
    return (
      <>
        <Link to={`/${CurrentLocale}/news/${props.id}/${props.title}`} className="news-item inner">
          <div className="image">
            <img src={props.img} alt={props.title} />
            <div className="date">
              {CurrentLocale === 'en' ? moment(props.date).format('YYYY-M-D') :
                moment(props.date).format('jYYYY-jM-jD')}
            </div>
          </div>
          <div className="news-content">
            <div className="toolbar">
              {/*<div className="posted">
                <label>{this.context.postedIn}:</label>
                دسته بندی
              </div>
               <ul className="icons">
                <li>
                  <span className="mdi mdi-heart-outline"></span>&nbsp;0
                </li>
                <li>
                  <span className="mdi mdi-comment-outline"></span>&nbsp;0
                </li>
              </ul> */}
            </div>
            <div className="title">{props.title}</div>
            <div className="introtext">{props.excerpt}&nbsp;<span className="readmore">{this.context.readmore}</span></div>
          </div>
        </Link>
      </>
    );
  }
}

CountryCard.contextType = LangContext;
export default CountryCard;
