import React, { Component } from "react";

// Services
import Sidebar from '../../../components/sidebar';
import Lang from '../../../components/pwa-header/lang';
import { Link } from 'react-router-dom';
import * as Service from '../../../api/common';

// Components
import LargeSlider from '../../../components/slider-large';
import PwaHeader from '../../../components/pwa-header';
import PwaFooter from '../../../components/pwa-footer';
import Loading from '../../../container/site/other/loading';

import './index.css';

const CurrentLocale = localStorage.getItem("language");

class PwaSelecLang extends Component {
  state = {
    isLoading: true
  }

  constructor(props) {
    super(props);
  }

  open = () => {
    this.setState(
      state => ({
        openSidebar: !state.openSidebar
      })
    );
  };

  CloseSidebar = () => {
    if (this.state.openSidebar) {
      this.setState({
        openSidebar: false
      })
    }
  };

  componentWillUnmount() {
    //this.setState({ openSidebar: false });
  }

  componentDidMount() {
    this.getConfig();
  };

  getConfig = async () => {
    try {
      const { data } = await Service.getConfig(true);
      this.setState({
        logo: data.result.logo,
        isLoading: false
      });
    } catch (error) {
      console.error(error)
    }
  };


  render() {
    if (this.state.isLoading) {
      return (<Loading />);
    } else {

      return (
        <>
          <div className="home_top_section pos-r home_section no-scroll">
            <PwaHeader client={this.props.client} />
            <LargeSlider client={this.props.client} />

            
            <div className="main-header">
              <div className="row fullrow">
                <div className="large-12 medium-12 small-12 columns text-center">
                    <img src={this.state.logo} alt="" className="splash-logo" />
                </div>
              </div>

              <div className="row fullrow">
                <div className="large-12 medium-12 small-12 columns text-center">
                  <ul className="slash-lang-items">
                    {/* زبان ها */}
                    <Lang client={this.props.client} />
                  </ul>
                </div>
              </div>

            </div>


            
          </div>
          <PwaFooter />
        </>
      );
    }
  }
}
export default PwaSelecLang;