import React from "react";

export default () => (
  <svg viewBox="0 0 512 512">
		<path d="M263.2,2.93C261.33,1.07,258.76,0,256.13,0s-5.21,1.069-7.07,2.93c-1.87,1.86-2.93,4.44-2.93,7.07s1.06,5.21,2.93,7.069
			C250.92,18.93,253.5,20,256.13,20s5.2-1.07,7.07-2.931c1.859-1.859,2.93-4.439,2.93-7.069S265.06,4.79,263.2,2.93z"/>
		<path d="M509.073,238.931L406,135.807V74c0-11.028-8.972-20-20-20h-20V10c0-5.522-4.478-10-10-10h-62.333c-5.522,0-10,4.478-10,10
			s4.478,10,10,10H346v34H166V20h52.666c5.522,0,10-4.478,10-10s-4.478-10-10-10H156c-5.522,0-10,4.478-10,10v44h-20
			c-11.028,0-20,8.972-20,20v364H84c-5.522,0-10,4.478-10,10v44H20V266h64c5.522,0,10-4.478,10-10c0-5.522-4.478-10-10-10H10
			c-5.522,0-10,4.478-10,10v246c0,5.522,4.478,10,10,10h492c5.522,0,10-4.478,10-10V246
			C512,243.349,510.947,240.806,509.073,238.931z M312.667,74H386v76h-73.333V74z M312.667,170H386v76h-73.333V170z M312.667,266
			H386v76h-73.333V266z M312.667,362H386v76h-73.333V362z M219.333,74h73.334v76h-73.334V74z M219.333,170h73.334v76h-73.334V170z
			 M219.333,266h73.334v76h-73.334V266z M219.333,362h73.334v76h-73.334V362z M126,74h73.333v76H126V74z M126,170h73.333v76H126V170
			z M126,266h73.333v76H126V266z M126,362h73.333v76H126V362z M418,492H94v-34h302h22V492z M492,492h-54v-44c0-5.522-4.478-10-10-10
			h-22v-28.127h31.838c5.522,0,10-4.478,10-10c0-5.522-4.478-10-10-10H406v-27.958h31.838c5.522,0,10-4.478,10-10
			c0-5.522-4.478-10-10-10H406v-27.957h31.838c5.522,0,10-4.478,10-10c0-5.522-4.478-10-10-10H406V266h31.838
			c5.522,0,10-4.478,10-10c0-5.522-4.478-10-10-10H406v-81.901l86,86.042V492z"/>
		<path d="M70.069,400.6c-1.859-1.871-4.439-2.93-7.069-2.93s-5.21,1.06-7.07,2.93c-1.86,1.86-2.93,4.44-2.93,7.07
			s1.069,5.21,2.93,7.07c1.86,1.859,4.44,2.93,7.07,2.93s5.21-1.07,7.069-2.93c1.86-1.86,2.931-4.44,2.931-7.07
			S71.93,402.46,70.069,400.6z"/>
		<path d="M63,288.724c-5.522,0-10,4.478-10,10V370c0,5.522,4.478,10,10,10c5.522,0,10-4.478,10-10v-71.276
			C73,293.202,68.522,288.724,63,288.724z"/>
	</svg>
)