import React, { Component } from "react";

// Services
import * as Service from '../../../api/common';

// Components
import { Link } from "react-router-dom";
import Icon4 from "../../../assets/svg/news";
import ReactHtmlParser from 'react-html-parser';
import Layout from "../../../components/inner-layout";
import { LangContext } from '../../../App/languageContext';
import $ from 'jquery';

import "./item.css";
const CurrentLocale = localStorage.getItem("language");

class BlogItem extends Component {
  state = {
    item: "",
    itemAttachments: ""
  }

  componentDidMount() {
    this.get_item_by_id(this.props.match.params.id)
  }

  get_item_by_id = async (id) => {
    try {
      const { data } = await Service.getNew(id);
      this.setState({ item: data.result })
    } catch (error) {
      console.error(error);
    }
  }
// add
  get_item_attachments_by_id = async (id) => {
    try {
      const { data } = await Service.getNewAttachment(id);
      this.setState({ itemAttachments: data.result })
    } catch (error) {
      console.error(error);
    }
  }

  showGetAttachmentsForm(e){
    $(e.target).hide();
    $("#getAttachmentsForm").show();
  }
  sendAttachmentsForm = async (e) => {
    e.preventDefault();
    var formData = $('#getAttachmentsForm > form').serializeArray();
    $("#getAttachmentsForm").html("<button class='button send' type='button'> "+this.context.sending+'</button>');
    try {
      await Service.SendDowloadLog(formData);
      const { data } = await Service.getNewAttachment(this.props.match.params.id);
      if(typeof data !== "undefined" &&  data.result.attachments.length > 0){
        var dlBtns = "";
        for(var i=0; i < data.result.attachments.length; i++){
          var link = data.result.attachments[i]["file"];
          var title = data.result.attachments[i]["title"];
          dlBtns += "<a target='_new' href='"+link+"' class='button send'> "+title+'</a><br>';
        }
        $("#getAttachmentsForm").html(dlBtns);
      }else{
        $("#getAttachmentsForm").html("<button class='button send' type='button'> "+this.context.downlodForm_nofile+'</button>');
      }
    } catch (error) {
      $("#getAttachmentsForm").html("<button class='button send' type='button'> "+this.context.error+'</button>');
      console.error(error);
    }
    return false;
  }

  createFilesDiv(item) {
    if(item && item.category){
      if(item.category.slug == "tender" || item.category.slug == "auction"){
        return (
          <center>
            <button className="button send" type="button" onClick={(e) => this.showGetAttachmentsForm(e)} > {this.context.download2} </button>
            
            <div id="getAttachmentsForm" className="send-message-form column medium-12 large-12 small-12 " style={{display:"none"}}>
              <form action="" method="get" onSubmit={(e) => this.sendAttachmentsForm(e)} >
                <input type="hidden" 
                    name="new_id" 
                    value={this.props.match.params.id}
                  />
                <div className="input-name-contact">
                  <input type="text" 
                    className="winput" 
                    required="required" 
                    name="company" 
                    placeholder={this.context.downlodForm_company}
                  />
                </div>

                <div className="input-name-contact">
                  <input type="hidden" 
                    className="winput" 
                    required="" 
                    name="company_ceo" 
                    placeholder={this.context.downlodForm_company_ceo}
                  />
                </div>

                <div className="input-name-contact">
                  <input type="email" 
                    className="winput" 
                    required="required" 
                    name="email" 
                    placeholder={this.context.email}
                  />
                </div>

                <div className="input-name-contact">
                  <input type="tel" 
                    className="winput" 
                    required="required" 
                    name="tel" 
                    placeholder={this.context.tel}
                  />
                </div>

                <div className="input-name-contact">
                  <input type="tel" 
                    className="winput" 
                    required="required" 
                    name="mobile" 
                    placeholder={this.context.mobile}
                  />
                </div>

                <div className="input-name-contact">
                  <input type="text" 
                    className="winput" 
                    required="required" 
                    name="company_code" 
                    placeholder={this.context.downlodForm_company_code}
                  />
                </div>

                <div className="input-name-contact">
                  <input type="text" 
                    className="winput" 
                    required="required" 
                    name="economic_code" 
                    placeholder={this.context.downlodForm_economic_code}
                  />
                </div>

                <div className="input-name-contact">
                  <input type="text" 
                    className="winput" 
                    required="required" 
                    name="related_pepole" 
                    placeholder={this.context.downlodForm_related_pepole}
                  />
                </div>



                <div className="row small-12 text-center">
                  <button className="btn-send-msg-new" type="submit">{this.context.download2}</button>
                </div>
              </form>
            </div>
          </center>
        )
      }
    }
  }

  render() {
    Service.setPageTitle();
    const item = this.state.item;
    return (
      <>
        <div className="bread_crumb">
          <div className="row text-center content">
            <div className="page_title">
              <Icon4 />
              <span>{item.title}</span>
            </div>
            <ul className="br-menu">
              <li><Link to={`/${CurrentLocale}/home`}><span className="mdi mdi-home-outline"></span></Link></li>
              <li><Link to={`/${CurrentLocale}/news`}>{this.context.news}</Link></li>
              <li><span>{item.title}</span></li>
            </ul>
          </div>
        </div>
        <div className="page-content">
          <div className="row columns">
            <div className="news_item mb-15">
              <div className="large-8 medium-6 float-center">
                
                <div className="news-img mb-30 text-center">
                  {item.showImg ? <img src={item.img} alt={item.title} /> : ""}
                </div>
              </div>
              <div className="news-info">
                <h1>{item.title}</h1>
                {/* <div className="blog_intro">
                  <span>
                    <i className="mdi mdi-grease-pencil"></i>&nbsp;{this.context.postedIn}&nbsp;اخبار
                  </span>
                  <span>
                    <i className="mdi mdi-comment-outline"></i>&nbsp;0&nbsp;{this.context.comment}
                  </span>
                  <span>
                    <i className="mdi mdi-heart-outline"></i>&nbsp;0&nbsp;{this.context.like}
                  </span>
                </div> */}
              </div>
              {item ? ReactHtmlParser(item.body) : null}
              <div className="files-div">
              {this.createFilesDiv(item)}
              </div>
            </div>
          </div>

          {/* <div className="row columns">
            <div className="comment_section">
              <h6>{this.context.SubmitComments}</h6>
              <div className="row">
                <div className="large-4 medium-4 columns comment_send">
                  <form>
                    <div className="form-input">
                      <input
                        type="text"
                        autoComplete="off"
                        required=""
                        placeholder={
                          this.context.name + '' +
                          this.context.and + ' ' +
                          this.context.lastName
                        }
                      />
                      <span className="mdi mdi-account-outline"></span>
                    </div>

                    <div className="form-input">
                      <input
                        type="Email"
                        autoComplete="off"
                        required=""
                        placeholder={this.context.Email}
                      />
                      <span className="mdi mdi-email-outline"></span>
                    </div>

                    <div className="form-textarea">
                      <textarea
                        type="Email"
                        autoComplete="off"
                        required=""
                        placeholder={this.context.messageText}
                      ></textarea>
                      <span className="mdi mdi-email-outline"></span>
                    </div>
                    <button className="button send" type="submit">
                      {this.context.Sendmessage}
                    </button>
                  </form>
                </div>
                <div className="large-8 medium-8 columns">
                  <div className="comment_list">
                    <div className="item">
                      <div className="avater">
                        <i className="mdi mdi-account-outline"></i>
                      </div>

                      <div className="content">
                        <div className="top_bar">
                          <div className="dis">
                            <span className="name">مدیر سایت</span>
                            <span className="date">1398/05/12</span>
                          </div>
                          <div className="icon">
                            <span>
                              0 &nbsp;
                              <i className="mdi mdi-thumb-up-outline"></i>
                            </span>
                            <span>
                              0 &nbsp;
                              <i className="mdi mdi-thumb-down-outline"></i>
                            </span>
                          </div>
                        </div>
                        <div className="text">
                          <p>
                            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
                            صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها
                            و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که
                            لازم است
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="item">
                      <div className="avater">
                        <i className="mdi mdi-account-outline"></i>
                      </div>

                      <div className="content">
                        <div className="top_bar">
                          <div className="dis">
                            <span className="name">محمد محمدی</span>
                            <span className="date">1398/05/12</span>
                          </div>
                          <div className="icon">
                            <span>
                              12 &nbsp;{" "}
                              <i className="mdi mdi-thumb-up-outline"></i>
                            </span>
                            <span>
                              4 &nbsp;
                              <i className="mdi mdi-thumb-down-outline"></i>
                            </span>
                          </div>
                        </div>
                        <div className="text">
                          <p>
                            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
                            صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها
                            و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که
                            لازم است
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="item">
                      <div className="avater">
                        <i className="mdi mdi-account-outline"></i>
                      </div>

                      <div className="content">
                        <div className="top_bar">
                          <div className="dis">
                            <span className="name">محمد محمدی</span>
                            <span className="date">1398/05/12</span>
                          </div>
                          <div className="icon">
                            <span>
                              12 &nbsp;{" "}
                              <i className="mdi mdi-thumb-up-outline"></i>
                            </span>
                            <span>
                              4 &nbsp;
                              <i className="mdi mdi-thumb-down-outline"></i>
                            </span>
                          </div>
                        </div>
                        <div className="text">
                          <p>
                            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
                            صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها
                            و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که
                            لازم است
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </>
    );
  }
}
BlogItem.contextType = LangContext;
export default Layout(BlogItem);
