import React, { Component } from "react";

// Services
import * as Service from '../api/common';
// Components
import ScrollToTop from "./scrollTop";
import { LangContext } from "./languageContext";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Swipe from '../App/functions';



// site styles
import "../assets/css/foundation.css";
import "../assets/css/app.css";
import "../container/mobile/mobile.css";

//
import Loading from '../container/site/other/loading';
import $ from 'jquery'; 


// ===========================================
// PWA Routers
// ===========================================
import PwaSelecLang from "../container/pwa/selectLang";
import PwaHome from "../container/pwa/home";
import PwaHome_otherLangs from "../container/pwa/home_otherLangs";
import PwaBlogList from "../container/pwa/blog/list";
import PwaBlogItem from "../container/pwa/blog/item";
import PwaProductList from "../container/pwa/product-list";
import PwaProduct from "../container/pwa/product";
import PwaAboutCompany from "../container/pwa/about-company/index";
import PwaAchievements from "../container/pwa/about-company/achievements";
import PwaManagers from "../container/pwa/about-company/managers";
import PwaOrganizationalChart from "../container/pwa/about-company/organizational-chart";
import PwaRules from "../container/pwa/about-company/rules";
import PwaSubsidiaryCompanies from "../container/pwa/about-company/subsidiary-companies";
import PwaTrailer from "../container/pwa/trailer";
import PwaUnitsList from "../container/pwa/units/list";
import PwaUnitItem from "../container/pwa/units/item";
import PwaSuppliesList from "../container/pwa/supplie/list";
import PwaSuppliesItem from "../container/pwa/supplie/item";
import PwaGalleryList from "../container/pwa/gallery/list";
import PwaGalleryItem from "../container/pwa/gallery/item";
import PwaContact from "../container/pwa/contact";
import PwaPage from "../container/pwa/pages";
import PwaPollPage from "../container/pwa/poll/index";
import PwaOrderPage from "../container/pwa/order/index";

// 404
import NotFoundPage from './NotFoundPage';


class AppPwa extends Component {
  constructor(props) {
    super(props);  
    this.state = {
      ss: "",
      loading: true,
      currentLang: "",
      isMainLangs: false,
      client: localStorage.getItem("client"),
      isPWA: localStorage.getItem("isPWA")
      // currentAppLocale: AppLocale['fa']
    }
   
}

  


  componentDidMount = async () => {
    this.state.currentLang = localStorage.getItem("language");
    if(this.state.currentLang == 'fa' || this.state.currentLang == 'en'){
      this.setState({isMainLangs:true});
    }
    $(document).ready( e => {
        this.setState({ loading:false });
    });

    // Get the element yourself.


    
  }

  
  render() {
    if(this.state.loading){
      return(<Loading />);
    }else{
      let allWords = Service.getAllWords();
    return (
      
      <>
      
        <LangContext.Provider value={allWords[this.state.currentLang]}>

          <Router>
            <ScrollToTop>

                { 
               
                localStorage.getItem("client") == "pwa" ? (
                  <Switch>
                    <Route exact path={`/`} component={(props) => <PwaSelecLang {...props} client={this.props.client} />} />
                    <Route exact path={`/:lang/home`} component={(props) => 
                    this.state.isMainLangs ? <PwaHome {...props} isHome={true} client={this.props.client} config={this.props.config} /> : <PwaHome_otherLangs {...props} client={this.props.client} config={this.props.config} />
                    } /> 
                    <Route exact path={`/:lang/about-our-company`} component={(props) => <PwaAboutCompany {...props} client={this.props.client} />} />
                    <Route exact path={`/:lang/achievements`} component={(props) => <PwaAchievements {...props} client={this.props.client} />} />
                    <Route exact path={`/:lang/managers`} component={(props) => <PwaManagers {...props} client={this.props.client} />} />
                    <Route exact path={`/:lang/rules`} component={(props) => <PwaRules {...props} client={this.props.client} />} />
                    <Route exact path={`/:lang/organizational-chart`} component={(props) => <PwaOrganizationalChart {...props} client={this.props.client} />} />
                    <Route exact path={`/:lang/subsidiary-companies`} component={(props) => <PwaSubsidiaryCompanies {...props} client={this.props.client} />} />
                    <Route exact path={`/:lang/trailer`} component={(props) => <PwaTrailer {...props} client={this.props.client} />} />
                    <Route exact path={`/:lang/company-units`} component={(props) => <PwaUnitsList {...props} client={this.props.client} />} />
                    <Route exact path={`/:lang/company-units/:id`} component={(props) => <PwaUnitItem {...props} client={this.props.client} />} />
                    <Route exact path={`/:lang/company-units/:id/:title`} component={(props) => <PwaUnitItem {...props} client={this.props.client} />} />
                    <Route exact path={`/:lang/products`} component={(props) => <PwaHome {...props} isHome={true} page="products" client={this.props.client} />} />
                    <Route exact path={`/:lang/products/:id/:title`} component={(props) => <PwaProduct {...props} client={this.props.client} />} />
                    <Route exact path={`/:lang/products/:id`} component={(props) => <PwaProduct {...props} client={this.props.client} />} />
                    <Route exact path={`/pwa/:lang/products/:id/:title`} component={(props) => <PwaProduct {...props} client={this.props.client} />} />
                    <Route exact path={`/pwa/:lang/products/:id`} component={(props) => <PwaProduct {...props} client={this.props.client} />} />
                    <Route exact path={`/:lang/news`} component={(props) => <PwaHome {...props} isHome={true} page="news" client={this.props.client} />} />
                    <Route exact path={`/:lang/news/:id/:title`} component={(props) => <PwaBlogItem {...props} client={this.props.client} />} />
                    <Route exact path={`/:lang/news/:id`} component={(props) => <PwaBlogItem {...props} client={this.props.client} />} />
                    <Route exact path={`/pwa/:lang/news/:id/:title`} component={(props) => <PwaBlogItem {...props} client={this.props.client} />} />
                    <Route exact path={`/pwa/:lang/news/:id`} component={(props) => <PwaBlogItem {...props} client={this.props.client} />} />
                    <Route exact path={`/:lang/news/:id`} component={(props) => <PwaBlogItem {...props} client={this.props.client} />} />
                    <Route exact path={`/:lang/news/:id/:title`} component={(props) => <PwaBlogItem {...props} client={this.props.client} />} />
                    <Route exact path={`/:lang/news-cats/:id`} component={(props) => <PwaBlogList {...props} client={this.props.client} />} />
                    <Route exact path={`/:lang/news-cats/:id/:title`} component={(props) => <PwaBlogList {...props} client={this.props.client} />} />
                    <Route exact path={`/:lang/supplies`} component={(props) => <PwaHome {...props} isHome={true} page="supplies" client={this.props.client} />} />
                    <Route exact path={`/:lang/supplies/:id`} component={(props) => <PwaSuppliesItem {...props}  client={this.props.client} />} />
                    <Route exact path={`/:lang/supplies/:id/:title`} component={(props) => <PwaSuppliesItem {...props}  client={this.props.client} />} />
                    <Route exact path={`/pwa/:lang/supplies/:id`} component={(props) => <PwaSuppliesItem {...props}  client={this.props.client} />} />
                    <Route exact path={`/:lang/gallery`} component={(props) => <PwaGalleryList {...props} client={this.props.client} />} />
                    <Route exact path={`/:lang/gallery/:id`} component={(props) => <PwaGalleryItem {...props} client={this.props.client} />} />
                    <Route exact path={`/:lang/contact-us`} component={(props) => <PwaHome {...props} isHome={true} page="contact-us" client={this.props.client} />} />
                    <Route exact path={`/:lang/page/:name`} component={(props) => <PwaPage {...props} client={this.props.client} />} />
                    <Route exact path={`/:lang/poll`} component={(props) => <PwaPollPage {...props} client={this.props.client} />} />
                    <Route exact path={`/:lang/order`} component={(props) => <PwaOrderPage {...props} client={this.props.client} />} />
                    
                    
                    <Route path="*" component={(props) => <PwaHome {...props} isHome={true} client={this.props.client} />} />

                  </Switch>
                ) : (
                  <Switch>
                    
                  </Switch>
                  )

              }

            </ScrollToTop>
          </Router>
        </LangContext.Provider>
      </>
    );
    }
  }
}
export default AppPwa;
