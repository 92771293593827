import React, { Component } from "react";

// Services
 import * as Services from '../../../api/common';

// Components
import LargeSlider from '../../../components/slider-large';
import Header from '../../../components/header';
import LargeMenu from './large-menu';
import News from './news';
import Boards from './supplies';
// import Catalog from './catalog';
// import Gallery from './gallery';
import Footer from '../../../components/footer';
import ArrowDown from '../../../assets/svg/arrow-down';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Loading from '../../../container/site/other/loading';

import './index.css';

class Home extends Component {
  state = {
    isLoading: true
  }

  constructor(props){
    super(props);
  }

  componentDidMount() {
    this.setState({isLoading:false});
  }

  render() {
    Services.setPageTitle();
    if(this.state.isLoading){
      return(<Loading />);
    }else{
      return (
        <>
          <div className="home_top_section pos-r home_section"  style={{ backgroundColor: this.props.config ? this.props.config["slider_backcolor"]:"#153874" }}>
            <Header  />
            <LargeSlider  />
            {/* فلش پایین اسلایدر */}
            <AnchorLink href="#NewsArchive" className="arrow"  >
              <ArrowDown  />
            </AnchorLink>
          </div>
          <News  />

          <LargeMenu {...this.props} />

          <Boards  />
          {/* <Catalog />
          <Gallery /> */}
          <Footer  />
        </>
      );
    }
  }
}
export default Home;