import React, { Component } from "react";
import * as Service from '../../../api/common';

// Components
import { Link } from 'react-router-dom';
import Title from '../../../components/title';
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import moment from 'moment-jalaali';
import Spinner from '../../../components/spinner';
import { LangContext } from '../../../App/languageContext';
const CurrentLocale = localStorage.getItem("language") ? localStorage.getItem("language") : "fa";
const Direction = CurrentLocale === 'en' ? false : true;

class supplies extends Component {
  state = {
    options: {
      items: 4,
      dots: true,
      nav: false,
      margin: 30,
      rewind: true,
      rtl: Direction,
      loop: true,
      autoplay: false,
      autoplayTimeout: 5000,
      autoplayHoverPause: true,
      smartSpeed: 600,
      responsive: {
        0: {
          items: 1,
        },
        540: {
          items: 2,
        },
        800: {
          items: 2,
        },
        1025: {
          items: 3,
        },
        1200: {
          items: 3,
        }
      }
    },
    slider: ""
  }

  componentDidMount() {
    this.get_slider()
  }

  get_slider = async () => {
    try {
      const { data } = await Service.getRecentSupplies();
      this.setState({ slider: data.result })
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <>
        <div className="board-home">
          <div className="row columns">
            <Title text={this.context.supplies} icon="bulletin-board" dark="true" />
          </div>
          <div className="row columns">
            {this.state.slider ?
              <OwlCarousel ref="slider5" options={this.state.options}>
                {this.state.slider.map((item, key) =>
                  <div className="board-item" key={key}>
                    <Link to={`/${CurrentLocale}/supplies/${item.id}`} className="image" title={item.title}>
                      <img src={item.img} title={item.title} alt={item.title} />
                    </Link>
                    <div>
                      <div className="title">{item.title}</div>
                      <div className="date">{this.context.date}:&nbsp;{CurrentLocale === 'en' ? moment(item.date).format('YYYY-M-D') : moment(item.date).format('jYYYY-jM-jD')}</div>
                      <Link to={`/${CurrentLocale}/supplies/${item.id}`} title={item.title} className="readmore">{this.context.viewNotifi}&nbsp;<span className="mdi mdi-chevron-double-left"></span></Link>
                    </div>
                  </div>
                )}
              </OwlCarousel>
              : <Spinner />}
          </div>
        </div>
      </>
    )
  }
}
supplies.contextType = LangContext;
export default supplies;