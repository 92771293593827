
import React, { Component } from "react";
import * as Service from '../../api/common';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';

import './index.css';
class InnerPopup extends Component {
  state = {
    isLoading: true,
    popupContent:''
  }
  async componentWillMount(){
    var conf = await Service.getConfig(true);
    this.state.popupContent = conf.data.result.popup;
  }
  componentDidMount() {
    
  }

  HidePopup(){
    $('.inner-popup').hide();
  }

  render() {
    
    return (
      <>
        <div className="inner-popup" onClick={this.HidePopup}>
         {ReactHtmlParser(this.state.popupContent)} 
        </div>
      </>
    )
  }
}
export default InnerPopup;
