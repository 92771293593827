import React from 'react';

import Spinner from './loader';
import './form.css';

const form = ({ text, loading, ...props }) => {
  return (
    <form {...props} className="form-loader">
      {loading && (
        <div className="loader-box">
          <Spinner text={text} />
        </div>
      )}
      {props.children}
    </form>
  );
};

export default form;
