import React, { Component } from "react";

class NotFound extends Component {
    render() {
        return (
        <>
            <div className="row columns text-center">
                <br/>
                <br/>
                <br/>
                <h1>404</h1>
                <div className="h3">Opps..! Page Not Found</div>
                <button className="button primary" onClick={()=>window.history.back()}>Return to previous page</button>
            </div>
        </>
        )
    }
}
export default NotFound;