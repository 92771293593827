import axios from 'axios';


function setAuthToken(token) {
  //   // APIClient.defaults.headers['Cache-Control'] = 'no-cache';
  //   // APIClient.defaults.headers.Pragma = 'no-cache';
  //   APIClient.defaults.headers.Authorization = `Bearer 6fe6c750c23ae95dcf73de5dbfda2fbe`;
}

const { NODE_ENV, REACT_APP_API_URL } = process.env;
let baseURL;

if (NODE_ENV === "development") {
  baseURL = `${REACT_APP_API_URL}/admin/api/v1`;
} else if (NODE_ENV === "production") {
  baseURL = `${window.SERVER}/admin/api/v1`;
}
const APIClient = axios.create({ baseURL });

export { APIClient, setAuthToken };
