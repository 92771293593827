import React, { Component } from "react";

// Service
import * as Service from '../../../api/common';

// Components
import Icon from './video';
import Title from '../../../components/title';
import ReactHtmlParser from 'react-html-parser';
import Layout from "../../../components/inner-layout";
import { LangContext } from '../../../App/languageContext';
import { Link } from 'react-router-dom';

const CurrentLocale = localStorage.getItem("language");

class AboutCompany extends Component {
  state = {
    item: ""
  }

  componentDidMount() {
    this.getItem();
  }

  getItem = async () => {
    try {
      const { data } = await Service.getPage(`${CurrentLocale}/trailer`)
      this.setState({
        item: data.result
      })
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const item = this.state.item;
    return (
      <>
        <div className="bread_crumb">
          <div className="row text-center content">
            <div className="page_title">
              <Icon />
              <span>{this.context.trailer}&nbsp;{this.context.JeyOil}</span>
            </div>
            <ul className="br-menu">
              <li><Link to={`/${CurrentLocale}/home`}><span className="mdi mdi-home-outline"></span></Link></li>
              <li>
                <span>{this.context.trailer}&nbsp;{this.context.JeyOil}</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="page-content">
          <div className="row columns">
            <Title icon="domain">{this.context.trailer}&nbsp;{this.context.JeyOil}</Title>
            {item ? ReactHtmlParser(item.body) : null}
          </div>
        </div>
      </>
    )
  }
}
AboutCompany.contextType = LangContext;
export default Layout(AboutCompany)