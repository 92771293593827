import React, { Component } from "react";

// Components

import { LangContext } from '../../App/languageContext';

import './index.css';
//import '../pwa-global/css/bootstrap.min.css';
import '../pwa-global/css/pwa/style.css';

const CurrentLocale = localStorage.getItem("language");

class PwaHeader extends Component {
  state = {
    openSidebar: false,
    isLoading: true,
    logo: ""
  }

  constructor(props){
    super(props);
  }

  componentWillMount() {

    // if(CurrentLocale == "fa"){
    //   require('../pwa-global/css/style-rtl.css');
    // }else{
    //   require('../pwa-global/css/style-ltr.css');
    // }

    // require('../pwa-global/css/style.css');
}

  render() {
    return (
      <>
        
        
      </>
    );
  }
}
PwaHeader.contextType = LangContext;
export default PwaHeader;