import React, { Component } from "react";

// Services
import * as Service from '../../../api/common';

// Components
import Icon4 from "../../../assets/svg/news";
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import Layout from "../../../components/inner-layout";
import { LangContext } from '../../../App/languageContext';
const CurrentLocale = localStorage.getItem("language");

class SuppliesItem extends Component {

  state = {
    item: ""
  }

  constructor(props){
    super(props);
  }
  componentDidMount() {
    this.get_item_by_id(this.props.match.params.id)
  }

  get_item_by_id = async (id) => {
    try {
      const { data } = await Service.getSupply(id);
      this.setState({
        item: data.result
      })
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    Service.setPageTitle();
    const item = this.state.item;
    return (
      <>
        <div className="bread_crumb">
          <div className="row text-center content">
            <div className="page_title">
              <Icon4 />
              <span>{item.title}</span>
            </div>
            <ul className="br-menu">
              <li><Link to={`/${CurrentLocale}/home`}><span className="mdi mdi-home-outline"></span></Link></li>
              <li><Link to={`/${CurrentLocale}/supplies`}>{this.context.supplies}</Link></li>
              <li><span>{item.title}</span></li>
            </ul>
          </div>
        </div>
        <div className="page-content">
          <div className="row columns">
            <div className="news_item mb-15">
              <div className="news-img mb-30 text-center">
                <img src={item.img} alt={item.title} />
              </div>
              <div className="news-info">
                <h1>{item.title}</h1>
                {/* <div className="blog_intro">
                  <span>
                    <i className="mdi mdi-grease-pencil"></i>&nbsp;{this.context.postedIn}&nbsp;عرضه ها
                  </span>
                  <span>
                    <i className="mdi mdi-comment-outline"></i>&nbsp;0&nbsp;{this.context.comment}
                  </span>
                  <span>
                    <i className="mdi mdi-heart-outline"></i>&nbsp;0&nbsp;{this.context.like}
                  </span>
                </div> */}
              </div>
              {item ? ReactHtmlParser(item.body) : null}
            </div>
          </div>

          {/* <div className="row columns">
            <div className="comment_section">
              <h6>{this.context.SubmitComments}</h6>
              <div className="row">
                <div className="large-4 medium-4 columns comment_send">
                  <form>
                    <div className="form-input">
                      <input
                        type="text"
                        autoComplete="off"
                        required=""
                        placeholder={
                          this.context.name + ' ' +
                          this.context.and + ' ' +
                          this.context.lastName
                        }
                      />
                      <span className="mdi mdi-account-outline"></span>
                    </div>

                    <div className="form-input">
                      <input
                        type="Email"
                        autoComplete="off"
                        required=""
                        placeholder={this.context.Email}
                      />
                      <span className="mdi mdi-email-outline"></span>
                    </div>

                    <div className="form-textarea">
                      <textarea
                        type="Email"
                        autoComplete="off"
                        required=""
                        placeholder={this.context.messageText}
                      ></textarea>
                      <span className="mdi mdi-email-outline"></span>
                    </div>
                    <button className="button send" type="submit">
                      {this.context.Sendmessage}
                    </button>
                  </form>
                </div>
                <div className="large-8 medium-8 columns">
                  <div className="comment_list">
                    <div className="item">
                      <div className="avater">
                        <i className="mdi mdi-account-outline"></i>
                      </div>

                      <div className="content">
                        <div className="top_bar">
                          <div className="dis">
                            <span className="name">مدیر سایت</span>
                            <span className="date">1398/05/12</span>
                          </div>
                          <div className="icon">
                            <span>
                              0 &nbsp;
                              <i className="mdi mdi-thumb-up-outline"></i>
                            </span>
                            <span>
                              0 &nbsp;
                              <i className="mdi mdi-thumb-down-outline"></i>
                            </span>
                          </div>
                        </div>
                        <div className="text">
                          <p>
                            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
                            صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها
                            و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که
                            لازم است
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="item">
                      <div className="avater">
                        <i className="mdi mdi-account-outline"></i>
                      </div>

                      <div className="content">
                        <div className="top_bar">
                          <div className="dis">
                            <span className="name">محمد محمدی</span>
                            <span className="date">1398/05/12</span>
                          </div>
                          <div className="icon">
                            <span>
                              12 &nbsp;{" "}
                              <i className="mdi mdi-thumb-up-outline"></i>
                            </span>
                            <span>
                              4 &nbsp;
                              <i className="mdi mdi-thumb-down-outline"></i>
                            </span>
                          </div>
                        </div>
                        <div className="text">
                          <p>
                            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
                            صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها
                            و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که
                            لازم است
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="item">
                      <div className="avater">
                        <i className="mdi mdi-account-outline"></i>
                      </div>

                      <div className="content">
                        <div className="top_bar">
                          <div className="dis">
                            <span className="name">محمد محمدی</span>
                            <span className="date">1398/05/12</span>
                          </div>
                          <div className="icon">
                            <span>
                              12 &nbsp;{" "}
                              <i className="mdi mdi-thumb-up-outline"></i>
                            </span>
                            <span>
                              4 &nbsp;
                              <i className="mdi mdi-thumb-down-outline"></i>
                            </span>
                          </div>
                        </div>
                        <div className="text">
                          <p>
                            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
                            صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها
                            و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که
                            لازم است
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </>
    );
  }
}
SuppliesItem.contextType = LangContext;
export default Layout(SuppliesItem);
