import React from 'react';

import Header from './header';
import Footer from './footer';
import SmallSlider from './slider-small';

function Layout(WrapperComponent,changeLang) {
  return function (props) {
    return (
      <>
        <div className="inner_top_header">
          <Header changeLang={changeLang} />
          <SmallSlider />
        </div>
        
          <WrapperComponent {...props} />
      
        <Footer />
      </>
    );
  };
}

export default Layout;
