import React, { Component } from "react";
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { NavLink } from 'react-router-dom';
import onClickOutside from "react-onclickoutside";

// Services
import * as Service from '../../../api/common';

import $ from 'jquery';
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";

// Components
import LargeSlider from '../../../components/slider-large';
import PwaHeader from '../../../components/pwa-header';
import PwaFooter from '../../../components/pwa-footer';
//import PwaBottomNav from '../../../components/pwa-bottom-menu/index';
import PwaNavHeader from '../../../components/pwa-nav-header/index';
import Loading from '../../../container/site/other/loading';
import Spinner from '../../../components/loader';


import * as Yup from 'yup';
import Form from '../../../components/form';
import { Formik } from 'formik';

import homeIcon from '../../../components/pwa-global/img/icon/Home.svg';
import contactUsIcon from '../../../components/pwa-global/img/icon/Support.svg';
import newsIcon from '../../../components/pwa-global/img/icon/NewsPaper.svg';
import productIcon from '../../../components/pwa-global/img/icon/Product-new.svg';
import arzeIcon from '../../../components/pwa-global/img/icon/Board.svg';
import emailOutlineIcon from '../../../components/pwa-global/img/icon/email-outline.png';
import phoneIcon from '../../../components/pwa-global/img/icon/phone.png';
import mapMarkerIcon from '../../../components/pwa-global/img/icon/map-marker.png';
import twitterIcon from '../../../components/pwa-global/img/icon/twitter.png';
import instagramIcon from '../../../components/pwa-global/img/icon/instagram.png';
import googleplusIcon from '../../../components/pwa-global/img/icon/google-plus.png';
import likedinIcon from '../../../components/pwa-global/img/icon/LinkedIn.png';
import telegramIcon from '../../../components/pwa-global/img/icon/telegram.png';
import facebookIcon from '../../../components/pwa-global/img/icon/facebook.png';
import accountoutlineIcon from '../../../components/pwa-global/img/icon/account-outline.png';
import emailoutline01Icon from '../../../components/pwa-global/img/icon/email-outline01.png';



import './index.css';

class pwaHome extends Component {
  state = {
    initialValues: {
      name: '',
      email: '',
      message: '',
      type: ''
    },
    options: {
      items: 1,
      dots: true,
      nav: false,
      rewind: true,
      rtl: false,
      mouseDrag: true,
      touchDrag: true,
      loop: true,
      autoplay: true,
      autoplayTimeout: 4000,
      autoplayHoverPause: true,
      smartSpeed: 600,
      animateOut: 'fadeOut',
      animateIn: 'fadeIn'
    },
    isLoading: true,
    isLoading2: true,
    page: 'home',
    lang: localStorage.getItem("language"),
    logo: "",
    words: Service.getAllWords(),
    config: null,
    navMenu: null,
    list_limit: 25,
    supplies: null,
    supplies_offset: 0,
    total_supplies: 0,
    getting_supplies: false,

    products: null,
    products_offset: 0,
    total_products: 0,
    getting_products: false,

    news: null,
    news_offset: 0,
    total_news: 0,
    getting_news: false,


    item1_back: null,
    item1_icon: null,
    item1_text: null,
    item1_link: null,

    item2_back: null,
    item2_icon: null,
    item2_text: null,
    item2_link: null,

    item3_back: null,
    item3_icon: null,
    item3_text: null,
    item3_link: null,

    item4_back: null,
    item4_icon: null,
    item4_text: null,
    item4_link: null,

    item5_back: null,
    item5_icon: null,
    item5_text: null,
    item5_link: null,

    isHome: true

  }

  constructor(props) {
    super(props);
    this.state.lang = localStorage.getItem("language");
  }

  setData = async () => {

    this.state.config = await Service.getConfig();
    this.state.logo = this.state.config.data.result.logo;
    this.state.navMenu = await Service.getMainMenu();
    const { data } = await Service.getMobileHome();
    if (this.state.lang == "fa") {
      this.state.options.rtl = true;
    }
    var i = 0;
    data.result.items.forEach(item => {
      i++;
      switch (i) {
        case 1:
          this.state.item1_back = item.back;
          this.state.item1_icon = item.icon;
          this.state.item1_text = item.title;
          this.state.item1_link = item.link;
          break;

        case 2:
          this.state.item2_back = item.back;
          this.state.item2_icon = item.icon;
          this.state.item2_text = item.title;
          this.state.item2_link = item.link;
          break;

        case 3:
          this.state.item3_back = item.back;
          this.state.item3_icon = item.icon;
          this.state.item3_text = item.title;
          this.state.item3_link = item.link;
          break;

        case 4:
          this.state.item4_back = item.back;
          this.state.item4_icon = item.icon;
          this.state.item4_text = item.title;
          this.state.item4_link = item.link;
          break;

        case 5:
          this.state.item5_back = item.back;
          this.state.item5_icon = item.icon;
          this.state.item5_text = item.title;
          this.state.item5_link = item.link;
          break;
      }
    });


    this.setState({ getting_supplies: true, getting_products: true, getting_news: true });
    this.state.supplies = await Service.getSupplies(true, this.state.supplies_offset, this.state.list_limit);
    this.state.total_supplies = this.state.supplies.data.total;

    this.state.products = await Service.getProducts(true, this.state.products_offset, this.state.list_limit);
    this.state.total_products = this.state.products.data.total;

    this.state.news = await Service.getNews(true, this.state.news_offset, this.state.list_limit);
    this.state.total_news = this.state.news.data.total;


  }



  handleClickOut(event) {
    var ev = $(event.target).parents(".other-access-popup");
    if (ev && ev.length <= 0) {
      $(".other-access-popup").removeClass('active');
    }
  }

  componentDidMount = async () => {
    document.addEventListener("mousedown", this.handleClickOut);
    await this.setData();
    // document.addEventListener("mousedown", this.handleClickOutsideMenu);
    document.addEventListener("scroll", (event) => {
      this.loadMore();
    });
    //document.addEventListener('click', this.closeNav(Event), true);
    if (this.props.page) {
      this.changePage(null, this.props.page);
    }
    this.setState({ isLoading: false, getting_supplies: false, getting_products: false, getting_news: false });

  }

  componentWillUnmount() {
    //document.removeEventListener('click', this.closeNav(Event), true);
  }

  //shouldComponentUpdate(){
  componentDidUpdate() {
    if (typeof this.refs.iScroll !== "undefined") {
      document.addEventListener("scroll", (event) => {
        console.log(this);
        if (this.refs.iScroll.scrollTop + this.refs.iScroll.clientHeight >= this.refs.iScroll.scrollHeight) {

        }
      });
    }

    return true;
  }

  ClosePopupBox() {
    $(".other-access-popup").removeClass('active');
  }

  showMessageForm(type = 'message') {
    $('input[name="type"]').val(type);
    var mplaceholder = this.state.words[this.state.lang]["messageText"];
    switch (type) {
      case "shekayat":
        mplaceholder = this.state.words[this.state.lang]["complaintMessage"];
        break;
    }
    $('[name="message"]').attr('placeholder', mplaceholder);
    $('.send-message-btns').addClass('hide');
    $('.send-message-form').removeClass('hide');
  }

  hideMessageForm() {
    $('input[name="type"]').val('');
    $('.send-message-btns').removeClass('hide');
    $('.send-message-form').addClass('hide');
  }

  changePage(elm, page = '') {
    var pageA = page.split("/");
    var pageName = pageA[pageA.length - 1].trim();
    var isInternalLink = true;
    var InternalAction = null;
    switch (pageName) {
      case "arze":
      case "supplies":
        pageName = "supplies";
        break;
      case "contact-us":
      case "contactus":
        this.hideMessageForm();
        pageName = "contactus";
        break;

      case "news":
      case "products":
        break;

      case "":
      case "home":
        pageName = "home";
        break;

      case "other-access":
        InternalAction = "show_other_access_popup";
        isInternalLink = false;
        break;
      default:
        isInternalLink = false;
        break;
    }
    this.ClosePopupBox();
    if (isInternalLink) {
      $('.icon-menu-footer01').removeClass('active');
      $('.nbi-' + pageName).addClass('active');
      this.setState({ page: pageName });
    } else if (InternalAction) {
      switch (InternalAction) {
        case "show_other_access_popup":
          $('.' + pageName + "-popup").addClass('active');
          break;
      }
    } else {
      window.location = page;
    }
  }


  handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      let name = values.name,
        email = values.email,
        message = values.message;
      const { data } = await Service.SendForm(name, email, message)
      alert(data.result)
      resetForm();
      this.hideMessageForm();
    } catch (error) {
      console.error(error);
    }
    setSubmitting(false);
  };
  // ااعتبار سنجی فرم
  ValidationSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().required(),
    message: Yup.string().required(),
  });

  async loadMore() {
    switch (this.state.page) {
      case "supplies":
      case "arze":
        if (!this.state.getting_supplies) {
          this.state.getting_supplies = true;
          var newOffset = this.state.supplies_offset + this.state.list_limit;
          if (this.state.supplies_offset <= this.state.total_supplies) {
            var supplies = await Service.getSupplies(false, newOffset, this.state.list_limit);
            this.state.supplies_offset = newOffset;
            var suppliesHTML = ""
            if (supplies.data.result !== null && supplies.data.result.length > 0) {
              supplies.data.result.map((item, key) =>
                suppliesHTML += '<a href=' + item.link + '>\
                  <div class="card-news01"  >\
                    <div class="img-arze-card" style="'+ { backgroundImage: "url(" + item.img + ")" } + '"></div>\
                    <div class="div-text-arze">'+ item.title + '</div>\
                  </div>\
                </a>'
              );
            }
            $(".supplies-list").append(suppliesHTML);


          }
          this.state.getting_supplies = false;
        }
        break;


      case "news":
        if (!this.state.getting_news) {
          this.state.getting_news = true;
          var newOffset = this.state.news_offset + this.state.list_limit;
          if (this.state.news_offset <= this.state.total_news) {
            var news = await Service.getNews(false, newOffset, this.state.list_limit);
            this.state.news_offset = newOffset;
            var newsHTML = ""
            if (news.data.result !== null && news.data.result.length > 0) {
              news.data.result.map((item, key) =>
                newsHTML += '<a href=' + item.link + '>\
                  <div class="card-news01"  >\
                    <div class="img-arze-card" style="'+ { backgroundImage: "url(" + item.img + ")" } + '"></div>\
                    <div class="div-text-arze">'+ item.title + '</div>\
                  </div>\
                </a>'
              );
            }
            $(".news-list").append(newsHTML);


          }
          this.state.getting_news = false;
        }
        break;

    }
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div className="loader-box">
          <Spinner text={this.state.words[this.state.lang]["loading_txt"]?this.state.words[this.state.lang]["loading_txt"]:"J E Y  O I L"} />
        </div>
      );
    } else {
      return (
        <>
          <div className="home_top_section pos-r home_section" style={{ backgroundColor: this.state.config.data.result.slider_backcolor }}>
            <PwaHeader client={this.props.client} />
            <LargeSlider client={this.props.client} />

            <PwaNavHeader isHome={true} />


            {{
              home: (

                <div className="mainHeader">
                  <div className="container-naft-jey">
                    <div className="container-lef">

                      <a className="animated  bounceInRight delay-1s" onClick={e => this.changePage(e.target, this.state.item1_link)} to={this.state.item1_link} >
                        <div className="card-left01" style={{ backgroundImage: "url('" + this.state.item1_back + "')" }}   >
                          <div className="item-icon"><img src={this.state.item1_icon} /></div>
                          <div className="item-seprator"></div>
                          <div className="item-title">{this.state.item1_text}</div>
                        </div>
                      </a>

                      <a className="animated  bounceInRight delay-1s" onClick={e => this.changePage(e.target, this.state.item2_link)} to={this.state.item2_link}>
                        <div className="card-left01 sterach" style={{ backgroundImage: "url('" + this.state.item2_back + "')" }}>
                          <div className="item-icon"><img src={this.state.item2_icon} /></div>
                          <div className="item-seprator"></div>
                          <div className="item-title">{this.state.item2_text}</div>

                        </div>
                      </a>

                      <a className="animated  bounceInRight delay-1s" onClick={e => this.changePage(e.target, this.state.item3_link)} to={this.state.item3_link}>
                        <div className="card-left01" style={{ backgroundImage: "url('" + this.state.item3_back + "')" }}>
                          <div className="item-icon"><img src={this.state.item3_icon} /></div>
                          <div className="item-seprator"></div>
                          <div className="item-title">{this.state.item3_text}</div>

                        </div>
                      </a>

                    </div>

                    <div className="container-right">
                      <Link className="animated  bounceInRight delay-1s" to={this.state.item4_link}>
                        <div className="card-left01 " style={{ backgroundImage: "url('" + this.state.item4_back + "')" }}>
                          <div className="item-icon"><img src={this.state.item4_icon} /></div>
                          <div className="item-seprator"></div>
                          <div className="item-title">{this.state.item4_text}</div>
                        </div>
                      </Link>

                      <a className="animated  bounceInRight delay-1s" onClick={e => this.changePage(e.target, this.state.item5_link)} to={this.state.item5_link}>
                        <div className="card-left01" style={{ backgroundImage: "url('" + this.state.item5_back + "')" }}>
                          <div className="item-icon"><img src={this.state.item5_icon} /></div>
                          <div className="item-seprator"></div>
                          <div className="item-title">{this.state.item5_text}</div>
                        </div>
                      </a>

                    </div>


                  </div>


                </div>

              ),
              supplies: (
                <div className="main-page-news supplies-list" >
                  {
                    this.state.supplies && this.state.supplies.data.result.length > 0 ?
                      this.state.supplies.data.result.map((item, key) =>
                        <Link to={"/pwa"+item.link} key={key}>
                          <div className="card-news01"  >
                            <div className="img-arze-card" style={{ backgroundImage: "url(" + item.img + ")" }}></div>
                            <div className="div-text-arze">{item.title}</div>
                          </div>
                        </Link>


                      )
                      : null}

                </div>


              ),

              products: (
                <div className="main-page-news products-list" >
                  {
                    this.state.products && this.state.products.data.result.length > 0 ?
                      this.state.products.data.result.map((item, key) =>
                        <Link to={item.link} key={key}>
                          <div className="card-news01"  >
                            <div className="img-arze-card" style={{ backgroundImage: "url(" + item.img + ")" }}></div>
                            <div className="div-text-arze">{item.title}</div>
                          </div>
                        </Link>


                      )
                      : null}

                </div>


              ),

              news: (
                <div className="main-page-news news-list" >
                  {
                    this.state.news && this.state.news.data.result.length > 0 ?
                      this.state.news.data.result.map((item, key) =>
                        <Link to={"/pwa"+item.link} key={key}>
                          <div className="card-news01"  >
                            <div className="img-arze-card" style={{ backgroundImage: "url(" + item.img + ")" }}></div>
                            <div className="div-text-arze">{item.title}</div>
                          </div>
                        </Link>


                      )
                      : null}

                </div>


              ),
              contactus: (

                <div className="main-page-contact">

                  <div className="information-contact-us-new">

                    <OwlCarousel ref="slider2" options={this.state.options}>
                      <div className="item">
                        <div className="mail-info-new">
                          <div className="img-mail-icon-new"><img src={emailOutlineIcon} alt="" /></div>
                          <div className="text-mail-info-new">{ReactHtmlParser(this.state.config.data.result.info.contacts.email)}</div>
                        </div>

                        <div className="call-info-new">
                          <div className="img-call-icon-new"><img src={phoneIcon} alt="" /></div>
                          <div className="text-call-info-new">{ReactHtmlParser(this.state.config.data.result.info.contacts.tel)}</div>
                        </div>

                        <div className="location-info-new">
                          <div className="img-location-icon-new"><img src={mapMarkerIcon} alt="" /></div>
                          <div className="text-location-info-new"><a href={"geo:" + this.state.config.data.result.info.contacts.lat + "," + this.state.config.data.result.info.contacts.lng + "?q=" + this.state.config.data.result.info.contacts.lat + "," + this.state.config.data.result.info.contacts.lng} >{ReactHtmlParser(this.state.config.data.result.info.contacts.address)}</a></div>
                        </div>

                      </div>

                      <div className="item">
                        {/* <div className="mail-info-new">
                          <div className="img-mail-icon-new"><img src={emailOutlineIcon} alt="" /></div>
                          <div className="text-mail-info-new">{ReactHtmlParser(this.state.config.data.result.info.contacts.esfahan_email)}</div>
                        </div> */}

                        <div className="call-info-new">
                          <div className="img-call-icon-new"><img src={phoneIcon} alt="" /></div>
                          <div className="text-call-info-new">{ReactHtmlParser(this.state.config.data.result.info.contacts.esfahan_tel)}</div>
                        </div>

                        <div className="location-info-new">
                          <div className="img-location-icon-new"><img src={mapMarkerIcon} alt="" /></div>
                          <div className="text-location-info-new"><a href={"geo:" + this.state.config.data.result.info.contacts.esfahan_lat + "," + this.state.config.data.result.info.contacts.esfahan_lng + "?q=" + this.state.config.data.result.info.contacts.esfahan_lat + "," + this.state.config.data.result.info.contacts.esfahan_lng} >{ReactHtmlParser(this.state.config.data.result.info.contacts.esfahan_address)}</a></div>
                        </div>

                      </div>

                      <div className="item">
                        {/* <div className="mail-info-new">
                          <div className="img-mail-icon-new"><img src={emailOutlineIcon} alt="" /></div>
                          <div className="text-mail-info-new">{ReactHtmlParser(this.state.config.data.result.info.contacts.bandarabas_email)}</div>
                        </div> */}

                        <div className="call-info-new">
                          <div className="img-call-icon-new"><img src={phoneIcon} alt="" /></div>
                          <div className="text-call-info-new">{ReactHtmlParser(this.state.config.data.result.info.contacts.bandarabas_tel)}</div>
                        </div>

                        <div className="location-info-new">
                          <div className="img-location-icon-new"><img src={mapMarkerIcon} alt="" /></div>
                          <div className="text-location-info-new"><a href={"geo:" + this.state.config.data.result.info.contacts.bandarabas_lat + "," + this.state.config.data.result.info.contacts.bandarabas_lng + "?q=" + this.state.config.data.result.info.contacts.bandarabas_lat + "," + this.state.config.data.result.info.contacts.bandarabas_lng} >{ReactHtmlParser(this.state.config.data.result.info.contacts.bandarabas_address)}</a></div>
                        </div>
                      </div>
                    </OwlCarousel>


                  </div>



                  <div className="icon-social">
                    {/* <span className="icon-tiwtter"><a href="#"><img src={twitterIcon} alt="" /></a> </span> */}
                    <span className="icon-tiwtter"><a href={this.state.config.data.result.info.socials[0].link}><img src={likedinIcon} alt="" /></a> </span>
                    {/* <span className="icon-tiwtter"><a href={this.state.config.data.result.info.socials[5].link}><img src={telegramIcon} alt="" /></a> </span> */}
                    <span className="icon-tiwtter"><a href={this.state.config.data.result.info.socials[1].link}><img src={instagramIcon} alt="" /></a> </span>                    
                    {/* <span className="icon-tiwtter"><a href="#"><img src={facebookIcon} alt="" /></a> </span> */}
                  </div>

                  <div className="form-info-in-contact  ">

                    <div className="send-message-btns">

                      <div className="btn-div" >
                        <button className="btn-send-msg-new" onClick={e => this.showMessageForm('message')} type="button">{this.state.words[this.state.lang]["Sendmessage"]}</button>
                      </div>

                      <div className="btn-div" >
                        <button className="btn-send-msg-new" onClick={e => this.showMessageForm('shekayat')} type="button">{this.state.words[this.state.lang]["sendComplaint"]}</button>
                      </div>

                      
                    </div>


                    <Formik
                      enableReinitialize
                      initialValues={this.state.initialValues}
                      validationSchema={this.ValidationSchema}
                      onSubmit={this.handleSubmit}
                      render={({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                      }) => (
                          <Form
                            onSubmit={handleSubmit}
                            loading={isSubmitting}

                          >
                            <div className="send-message-form hide">
                              <div className="input-name-contact">
                                <input
                                  type="hidden"
                                  className="input-name"
                                  autoComplete="off"
                                  required="required"
                                  name="type"
                                  value={values.type}
                                  onBlur={handleBlur}
                                  error={touched.name && errors.name}
                                  onChange={handleChange}
                                />
                                <input
                                  type="text"
                                  className="input-name"
                                  autoComplete="off"
                                  required="required"
                                  name="name"
                                  value={values.name}
                                  onBlur={handleBlur}
                                  error={touched.name && errors.name}
                                  onChange={handleChange}
                                  placeholder={this.state.words[this.state.lang]["name"]}
                                />

                                <div className="icon-input-name"><img src={accountoutlineIcon} alt="" /></div>
                              </div>

                              <div className="input-mail-contact">
                                <input
                                  type="text"
                                  className="input-mail"
                                  autoComplete="off"
                                  required="required"
                                  name="email"
                                  value={values.email}
                                  onBlur={handleBlur}
                                  error={touched.email && errors.email}
                                  onChange={handleChange}
                                  placeholder={this.state.words[this.state.lang]["Email"]}
                                />
                                <div className="icon-input-mail"><img src={emailoutline01Icon} alt="" /></div>
                              </div>

                              <textarea
                                type="text"
                                className="inputcontact"
                                autoComplete="off"
                                required="required"
                                name="message"
                                value={values.message}
                                onBlur={handleBlur}
                                error={touched.message && errors.message}
                                onChange={handleChange}
                                rows="5"
                                placeholder={this.state.words[this.state.lang]["messageText"]}
                              ></textarea>
                              <button className="btn-send-msg" type="submit">{this.state.words[this.state.lang]["send"]}</button>
                            </div>
                          </Form>
                        )}
                    />
                  </div>




                </div>


              ),
              default: (
                <div></div>
              )
            }[this.state.page]}




          </div>


          <div className="menu-footer">
            <span className="icon-menu-footer01 nbi-home active">
              <div onClick={e => this.changePage(e.target, 'home')} >
                <div className="border-radius-icon "></div>
                <div>
                  <img className="bottom-nav-img" src={homeIcon} alt="" />
                </div>
                <div className="p-text-under-icon-menu ">{this.state.words[this.state.lang]["homee"]}</div>
              </div>
            </span>

            <span className="icon-menu-footer01 nbi-supplies">
              <div onClick={e => this.changePage(e.target, 'supplies')} >
                <div className="border-radius-icon "></div>
                <div>
                  <img className="bottom-nav-img" src={arzeIcon} alt="" />
                </div>
                <div className="p-text-under-icon-menu ">{this.state.words[this.state.lang]["supplies"]}</div>
              </div>
            </span>
            <span className="icon-menu-footer01 nbi-products">
              <div onClick={e => this.changePage(e.target, 'products')} >
                <div className="border-radius-icon "></div>
                <div>
                  <img className="bottom-nav-img" src={productIcon} alt="" />
                </div>
                <div className="p-text-under-icon-menu ">{this.state.words[this.state.lang]["products"]}</div>
              </div>
            </span>
            <span className="icon-menu-footer01 nbi-news">
              <div onClick={e => this.changePage(e.target, 'news')}  >
                <div className="border-radius-icon "></div>
                <div>
                  <img className="bottom-nav-img" src={newsIcon} alt="" />
                </div>
                <div className="p-text-under-icon-menu ">{this.state.words[this.state.lang]["news"]}</div>
              </div>
            </span>
            <span className="icon-menu-footer01 nbi-contactus">
              <div onClick={e => this.changePage(e.target, 'contactus')}  >
                <div className="border-radius-icon "></div>
                <div>
                  <img className="bottom-nav-img" src={contactUsIcon} alt="" />
                </div>
                <div className="p-text-under-icon-menu ">{this.state.words[this.state.lang]["contact"]}</div>
              </div>
            </span>


          </div>

          <div className="other-access-popup">
            <div className="clode-box-btn" onClick={e => this.ClosePopupBox()}><span> X </span></div>
            
            
            <div className="btn-div" >
            <Link to={`order`}>
              <button className="btn-send-msg-new" type="button">{this.state.words[this.state.lang]["sample_product_order"]}</button>
            </Link>
            </div>

{/* 
            <div className="btn-div" >
              <Link to={`poll`}>
              <button className="btn-send-msg-new" type="button">{this.state.words[this.state.lang]["send_poll"]}</button>
              </Link>
            </div> */}

            
            <div className="btn-div" >
              <button className="btn-send-msg-new" onClick={e => this.changePage(e.target, this.state.config.data.result.catalog_link)} type="button">{this.state.words[this.state.lang]["certificates"]}</button>
            </div>

            <div className="btn-div" >
              <button className="btn-send-msg-new" onClick={e => this.changePage(e.target, this.state.config.data.result.msds_link)} type="button">{this.state.words[this.state.lang]["msds"]}</button>
            </div>
          </div>

          <PwaFooter />
        </>
      );
    }
  }
}
export default pwaHome;