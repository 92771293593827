import React, { Component } from "react";

// Services
import * as Service from '../../../api/common';


// Components
import Layout from "../../../components/inner-layout";
import { LangContext } from '../../../App/languageContext';
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import { Link } from "react-router-dom";
import Icon1 from "../../../assets/svg/link";
import Icon4 from "../../../assets/svg/gallery";
import Loading from '../../../container/site/other/loading';



const CurrentLocale = localStorage.getItem("language");

const PhotoItem = ({ image, group, title }) => (
	<LightgalleryItem group={group} src={image}>
		<div className="large-3 medium-6 small-6 columns parent_product_item">
			<div className="product_item_List gallery_itm">
				<div className="container-img">
					<img
						className="image image-pro"
						src={image}
						alt={title}
						title={title}
					/>
					<div className="icon-more">
						<Icon1 />
					</div>
				</div>
			</div>
		</div>
	</LightgalleryItem>
);

class GalleryList extends Component {
	state = {
		AllData: [],
		loading: true
	};

	componentDidMount = async () => {
		await this.GetById(this.props.match.params.id)
	}

	GetById = async (id) => {
		try {
			const { data } = await Service.getGalleryById(id);
			this.setState({
				AllData: data.result,
				loading: false
			})
		} catch (error) {
			console.error(error);
		}
	}

	render() {
		const item = this.state.AllData;
		if(this.state.loading){
			return(
				<Loading />
			);
		}else{
		return (
			<>
				{/* مسیر سایت */}
				<div className="bread_crumb">
					<div className="row text-center content">
						<div className="page_title"><Icon4 /><span>{this.context.imageGallery}</span></div>
						<ul className="br-menu">
							<li><Link to={`/${CurrentLocale}/home`}><span className="mdi mdi-home-outline"></span></Link></li>
							<li><Link to={`/${CurrentLocale}/gallery`}>{this.context.imageGallery}</Link></li>
							<li><span>{item.title ? item.title : null}</span></li>
						</ul>
					</div>
				</div>
				<div className="page-content">
					{/* لیست گالری */}
					<div className="row">
						<LightgalleryProvider>
							{this.state.AllData.images ?
								this.state.AllData.images.map((img, key) => (
									<PhotoItem key={key} image={img} title={item.title} group="group3" />
								))
								: null}
						</LightgalleryProvider>
					</div>
				</div>
			</>
		);
		}
	}
}
GalleryList.contextType = LangContext;
export default Layout(GalleryList);
