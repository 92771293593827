import React, { Component } from "react";

// Services
import * as Services from '../../../api/common';
// Components
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import { Link } from 'react-router-dom';
import Title from '../../../components/title';
import moment from 'moment-jalaali';
import Spinner from '../../../components/spinner';
import { LangContext } from '../../../App/languageContext';
const CurrentLocale = localStorage.getItem("language") ? localStorage.getItem("language") : "fa";
const Direction = CurrentLocale === 'en' ? false : true;

class NewsSlider extends Component {
  state = {
    options: {
      items: 4,
      dots: true,
      nav: false,
      margin: 30,
      rewind: true,
      rtl: Direction,
      loop: false,
      autoplay: false,
      autoplayTimeout: 5000,
      autoplayHoverPause: true,
      smartSpeed: 600,
      responsive: {
        0: {
          items: 1,
        },
        540: {
          items: 2,
        },
        800: {
          items: 3,
        },
        1025: {
          items: 4,
        },
        1200: {
          items: 4,
        }
      }
    },
    items: ""
  }

  componentDidMount() {
    this.get_news();
  }

  get_news = async () => {
    try {
      const { data } = await Services.getRecentNews();
      this.setState({ items: data.result })
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <>
        <div className="news-home" id="NewsArchive">
          <div className="row columns">
            <Title text={this.context["latestNews"]} icon="newspaper" />
            {this.state.items ?
              <OwlCarousel ref="slider2" options={this.state.options}>
                {this.state.items.map((item, key) => (
                  <Link to={`/${CurrentLocale}/news/${item.id}/${item.title}`} className="news-item" key={key}>

                    <div className="image ">
                      <img src={item.img} alt={item.title} />
                      <div className="date">
                        {CurrentLocale === 'en' ? moment(item.date).format('YYYY-M-D') :
                          moment(item.date).format('jYYYY-jM-jD')}
                      </div>
                    </div>

                    <div className="toolbar">
                      {/* <div className="posted">
                        <label>{this.context.postedIn}:</label>
                        دسته بندی
                      </div>
                      <ul className="icons">
                        <li>
                          <span className="mdi mdi-heart-outline"></span>&nbsp;0
                        </li>
                        <li>
                          <span className="mdi mdi-comment-outline"></span>&nbsp;0
                        </li>
                      </ul> */}
                    </div>
                    <div className={`title ${(key === 0) ? 'pulse' : ''} `}>{item.title}</div>
                    <div className="introtext">
                      {item.excerpt}&nbsp;<span className="readmore">{this.context.readmore}</span>
                    </div>
                  </Link>
                ))}
              </OwlCarousel>
              : <Spinner />}
          </div>
        </div>
      </>
    )
  }
}
NewsSlider.contextType = LangContext;
export default NewsSlider;