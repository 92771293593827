import React, { Component } from "react";

// Services
import * as Service from '../../../api/common';


// Components
import Icon4 from "../../../assets/svg/product";
import Tabs from "../../../components/Tab/Tabs";
import ReactHtmlParser from 'react-html-parser';
import { Link } from "react-router-dom";
import Layout from "../../../components/inner-layout";
import { LangContext } from '../../../App/languageContext';
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import Spinner from "../../../assets/images/spinner.gif";


import "./index.css";
const CurrentLocale = localStorage.getItem("language");
const Direction = CurrentLocale === 'en' ? false : true;

const PhotoItem = ({ image, group, title }) => (
  <LightgalleryItem group={group} src={image}>

    <div className="item">
      <img src={image} title={title} alt={title} />
    </div>
  </LightgalleryItem>
);

class Product extends Component {
  state = {
    item: "",
    isLoading: true,
    options: {
      items: 4,
      dots: false,
      nav: false,
      rewind: true,
      rtl: Direction,
      loop: false,
      smartSpeed: 600,
      margin: 15
    }
  }

  componentDidMount() {
    this.get_item_by_id(this.props.match.params.id)
  }

  get_item_by_id = async (id) => {
    try {
      const { data } = await Service.getProduct(id);
      this.setState({
        item: data.result,
        isLoading: false
      })
    } catch (error) {
      console.error(error);
    }
  }
  render() {
    Service.setPageTitle();
    const item = this.state.item;
    const loading = this.state.isLoading;
    if(loading){
      return (
        <div className="loading-apge">
          <img src={Spinner} alt="spinner" />
        </div>
      );
    }else{
    return (
      <>
        {/* breadcrumb */}
        <div className="bread_crumb">
          <div className="row text-center content">
            <div className="page_title"><Icon4 /><span>{this.context.products}</span></div>
            <ul className="br-menu">
              <li><Link to={`/${CurrentLocale}/home`}><span className="mdi mdi-home-outline"></span></Link></li>
              <li><Link to={`/${CurrentLocale}/products`}>{this.context.products}</Link></li>
              <li><span>{item.title}</span></li>
            </ul>
          </div>
        </div>
        {/*  */}
        <div className="page-content">
          <div className="product_item">
            {/* محصول */}
            <div className="row ">
              <div className="medium-5 columns">
                <div className="img">
                  <img src={item.img} alt={item.title} title={item.title} />
                </div>
                {item.gallery ?
                  <LightgalleryProvider>
                    <div className="product_small_images">
                      <OwlCarousel ref="slider1" options={this.state.options}>
                        {item.gallery.map((gl, key) =>
                          <PhotoItem key={key} image={gl} title={item.title} group="group5" />
                        )}
                      </OwlCarousel>

                    </div>
                  </LightgalleryProvider>
                  : null}
              </div>
              <div className="medium-7 columns">
                <div className="context">
                  <span>{this.context.productName}</span>
                  <h1>{item.title}</h1>
                  <Tabs>
                    <div label={this.context.description}>
                      {item ? ReactHtmlParser(item.body) : null}
                    </div>
                    <div label={this.context.typesOfGrades}>
                      {item.technical_info ? ReactHtmlParser(item.technical_info) : this.context.NoTechnicalSpecifications}
                    </div>
                    <div label={this.context.TechnicalSpecifications}>
                      {item.attachments ?
                        <table className="tabel-download">
                          <thead>
                            <tr>
                              <th width="200">{this.context.fileName}</th>
                              <th>{this.context.fileSize}</th>
                              <th>{this.context.FileType}</th>
                              <th>{this.context.download}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {item.attachments.map((attach, key) =>
                              <tr key={key}>
                                <td>
                                  {attach.title}
                                </td>
                                <td>{attach.size}</td>
                                <td>{attach.type}</td>
                                <td>
                                  <a href={attach.file}><i className="mdi mdi-download"></i>&nbsp;{this.context.download}</a>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        : this.context.noFiles}
                    </div>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
    }
  }
}
Product.contextType = LangContext;
export default Layout(Product);
