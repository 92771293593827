import React, { Component } from "react";
import { Link, Redirect } from 'react-router-dom';

// const CurrentLocale = localStorage.getItem("language");

export default class LiLang extends Component {
    state = {
        isActive: "",
        redirectToHome: false,
        newLocale: '',
        newLang: ""
    };

    componentDidMount() {
        this.activer();
    }

    activer() {
        if (!localStorage.getItem("language")) {
            localStorage.setItem("language", 'fa');
            window.location.reload();
        }
        const active = localStorage.getItem("language");
        this.setState({ isActive: active });
    }
    changelang = async (e, lang) => {
        localStorage.setItem("language", lang);
        this.activer();
        e.className = "is-active";
        // window.location.href = "/";
        await this.setState({ redirectToHome: true, newLang: lang })
        window.location.reload()
    };

    render() {
        if (this.state.redirectToHome) {
            return (<Redirect to={`/${this.state.newLang}/home`} />)
        }
        return (
            <>
                {/* چندزبانه شدن ندارن. فقط باید بره به یه صفحه */}
                {/* <li
                    onClick={e => this.changelang(e.target, 'fa')}
                    className={this.state.isActive === 'fa' ? "is-active" : ""}
                >
                    <span>فارسی</span>
                </li>
                <li
                    onClick={e => this.changelang(e.target, 'en')}
                    className={this.state.isActive === 'en' ? "is-active" : ""}
                >
                    <span>English</span>
                </li>
                <li
                    onClick={e => this.changelang(e.target, 'ar')}
                    className={this.state.isActive === 'ar' ? "is-active" : ""}
                >
                    <span>العربیة</span>
                </li>
                <li
                    onClick={e => this.changelang(e.target, 'in')}
                    className={this.state.isActive === 'in' ? "is-active" : ""}
                >
                    <span>हिन्दी</span>
                </li>
                <li
                    onClick={e => this.changelang(e.target, 'cn')}
                    className={this.state.isActive === 'cn' ? "is-active" : ""}
                >
                    <span>中国</span>
                </li> */}
                <li
                    onClick={e => this.changelang(e.target, 'fa')}
                    className={this.state.isActive === 'fa' ? "is-active" : ""}
                >
                    <Link to={`/fa/home`}><span>فارسی</span></Link>
                </li>
                <li
                    onClick={e => this.changelang(e.target, 'en')}
                    className={this.state.isActive === 'en' ? "is-active" : ""}
                >
                    <Link to={`/en/home`}><span>English</span></Link>
                </li>
                <li
                    onClick={e => this.changelang(e.target, 'ar')}
                    className={this.state.isActive === 'ar' ? "is-active" : ""}
                >
                    <Link to={`/ar/home`}><span>العربیة</span></Link>
                </li>
                <li
                    onClick={e => this.changelang(e.target, 'in')}
                    className={this.state.isActive === 'in' ? "is-active" : ""}
                >
                    <Link to={`/in/home`}><span>हिन्दी</span></Link>
                </li>
                <li
                    onClick={e => this.changelang(e.target, 'cn')}
                    className={this.state.isActive === 'cn' ? "is-active" : ""}
                >
                    <Link to={`/cn/home`}><span>中国</span></Link>
                </li>
            </>
        );
    }
}
