import React, { Component } from "react";

// Services
import * as Service from '../../../../api/common';


// Components
import Icon4 from "../units";
import { Link } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import Layout from "../../../../components/inner-layout";
import { LangContext } from '../../../../App/languageContext';
import LargeSlider from '../../../../components/slider-large';
import PwaHeader from '../../../../components/pwa-header';
import PwaFooter from '../../../../components/pwa-footer';
import PwaNavHeader from '../../../../components/pwa-nav-header/index';


const CurrentLocale = localStorage.getItem("language");

class UnitItem extends Component {
  state = {
    item: "",
    lang: localStorage.getItem("language"),
    words: Service.getAllWords(),
    langWords: null
  }

  
  componentDidMount() {
   
    
    this.get_item_by_id(this.props.match.params.id)
  }

  get_item_by_id = async (id) => {
    try {
      const { data } = await Service.getCompanyUnit(id);
      this.setState({
        item: data.result
      })
    } catch (error) {
      console.error(error);
    }
  }
  render() {
    const item = this.state.item;
    this.state.langWords = this.state.words[this.state.lang];
    return (
      <>
        <div className="home_top_section pos-r home_section" >
          <PwaHeader client={this.props.client} />
          <LargeSlider client={this.props.client} />
          <PwaNavHeader />
          <div className="page-content page-company-unit">
            <div className="product_item">
              {/* محصول */}
              <div className="row ">
                <div className="medium-5 columns">
                  <div className="img">
                    <img src={item.img} alt="" />
                  </div>
                </div>
                <div className="medium-7 columns">
                  <div className="context">
                    <span>{this.state.langWords["UnitName"]}</span>
                    <h1>{item.title}</h1>
                    {/* چون تکی بود از تب نمیشد استفاده کرد */}
                    <ul className="tabs menu">
                      <li className="tabs-title is-active">
                        {this.state.langWords["description"]}
                      </li>
                    </ul>
                    <div className="tabs-content">
                      <div className="tabs-panel show">
                        {item ? ReactHtmlParser(item.body) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PwaFooter />
      </>
    );
  }
}
UnitItem.contextType = LangContext;
export default (UnitItem);
