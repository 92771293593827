import React, { Component } from "react";

// Services
import * as Service from '../../api/common';

// components
import onClickOutside from "react-onclickoutside";
import { NavLink } from 'react-router-dom';
import { LangContext } from '../../App/languageContext';

import './index.css';

class Sidebar extends Component {
  state = {
    menus: "",
    socials: []
  }
  handleClickOutside = evt => {
    this.props.close()
  };

  componentDidMount() {
    this.get_menus();
    this.getData();
  }

  get_menus = async () => {
    try {
      const { data } = await Service.getMainMenu();
      this.setState({
        menus: data.result
      })
    } catch (error) {
      console.error(error);
    }
  }
  getData = async () => {
    try {
      const { data } = await Service.getContact();
      this.setState({
        socials: data.result.socials
      })
    } catch (error) {
      console.error(error);
    }
  }

  addActiveClass(e) {
    const clicked = e.target.classList;
    if (clicked.contains('false')) {
      e.target.className = 'true';
    } else {
      e.target.className = 'false';
    }
  }

  render() {
    return (
      <>
        <div className={`sidebar ${this.props.openMenu ? 'is-active' : ''}`}>
          <span className="mdi mdi-close close" onClick={() => this.props.close()}></span>
          <ul className="side-menu">
            {this.state.menus && this.state.menus.length > 0 ?
              this.state.menus.map((item, key) =>
                <li key={key}>
                  {item.childs ?
                    <span className="false" onClick={this.addActiveClass}>
                      {item.title}&nbsp;
                      <i className="mdi mdi-chevron-down"></i>
                    </span>
                    :
                    <NavLink to={item.link} activeClassName="is-active">{item.title}</NavLink>
                  }
                  {(item.childs && item.childs.length > 0) ?
                    <ul className="sub_menu">
                      {item.childs.map((child, childKey) =>
                        <li key={childKey}>
                          <NavLink to={child.link} activeClassName="is-active">
                            <i className="mdi mdi-chevron-left"></i>&nbsp;
                            {child.title}
                          </NavLink>
                        </li>
                      )}
                    </ul>
                    : null}
                </li>
              )
              : null}
          </ul>
          <hr />
          <div className="side-social">
            <label>{this.context.follow}</label>
            <ul>
              {this.state.socials ?
                this.state.socials.map((item, key) =>
                  <li key={key}>
                    <a href={item.link} target="blank"><span className={`mdi mdi-${item.name}`} /></a>
                  </li>
                ) : null}
            </ul>
          </div>
        </div>
      </>
    )
  }
}
Sidebar.contextType = LangContext;
export default onClickOutside(Sidebar)