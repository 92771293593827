import React, { Component } from "react";

// Service
import * as Service from '../../api/common';

// components
import * as Yup from 'yup';
import Form from '../form';
import { Formik } from 'formik';

import { LangContext } from '../../App/languageContext';


class ContactForm extends Component {
  state = {
    initialValues: {
      name: '',
      email: '',
      message: ''
    }
  }

  handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      let name = values.name,
        email = values.email,
        message = values.message;
      const { data } = await Service.SendForm(name, email, message)
      alert(data.result)
      resetForm();
    } catch (error) {
      console.error(error);
    }
    setSubmitting(false);
  };
  // ااعتبار سنجی فرم
  ValidationSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().required(),
    message: Yup.string().required(),
  });

  render() {
    return (
      <>
        <Formik
          enableReinitialize
          initialValues={this.state.initialValues}
          validationSchema={this.ValidationSchema}
          onSubmit={this.handleSubmit}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue
          }) => (
              <Form
                onSubmit={handleSubmit}
                loading={isSubmitting}
                text={this.context.SendInformation}
              >
                <div className="galaxy-input">
                  <input
                    type="text"
                    autoComplete="off"
                    required="required"
                    name="name"
                    value={values.name}
                    onBlur={handleBlur}
                    error={touched.name && errors.name}
                    onChange={handleChange}
                  />
                  <label>{
                    this.context.name + ' ' +
                    this.context.and + ' ' +
                    this.context.lastName
                  }</label>
                  <div className="bar"></div>
                  <span className="mdi mdi-account-outline"></span>
                </div>
                <div className="galaxy-input">
                  <input
                    type="Email"
                    autoComplete="off"
                    required="required"
                    name="email"
                    value={values.email}
                    onBlur={handleBlur}
                    error={touched.email && errors.email}
                    onChange={handleChange}
                  />
                  <label>{this.context.Email}</label>
                  <div className="bar"></div>
                  <span className="mdi mdi-email-outline"></span>
                </div>
                <div className="galaxy-textarea">
                  <textarea
                    type="Email"
                    autoComplete="off"
                    required="required"
                    name="message"
                    value={values.message}
                    onBlur={handleBlur}
                    error={touched.message && errors.message}
                    onChange={handleChange}
                  ></textarea>
                  <label>{this.context.messageText}</label>
                  <div className="bar"></div>
                  <span className="mdi mdi-email-outline"></span>
                </div>

                <button className="galaxy-button" type="submit">{this.context.Sendmessage}</button>
              </Form>
            )}
        />
      </>
    )
  }
}
ContactForm.contextType = LangContext;
export default ContactForm;
