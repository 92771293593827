import React, { Component } from "react";

import * as Service from '../../../api/common';
import { Link } from 'react-router-dom';

// SVG icons
import Icon1 from '../../../assets/svg/home/news';
import Icon2 from '../about-company/company';
import Icon3 from '../../../assets/svg/home/units';
import Icon4 from '../../../assets/svg/home/product';

export default class Home extends Component {
  state = {
    menus: ""
  }
  constructor(props){
    super(props);
  }

  componentDidMount() {
    this.getMenus();
  }

  getMenus = async () => {
    try {
      const { data } = await Service.getDescktopUnderSlider();
      this.setState({ menus: data.result });
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <>
        <div className="large-menu pos-r" id="largeMEnu"   style={{ backgroundImage: `url(${this.props.config["items_back_img"]})`,backgroundAttachment: "fixed", backgroundPosition: "center", backgroundRepeat: "repeat-y", backgroundSize: "100% auto" , backgroundColor: "#f2f5f7" }} >
          <div className="row">
            {this.state.menus && this.state.menus.length > 0 ?
              this.state.menus.map((item, key) =>
                <div className="large-3 medium-3 small-6 columns" key={key}>
                  <Link className="large-menu-item" to={item.link}>
                    <div className="image">
                      <img src={item.image} alt="" />
                    </div>
                    <div className="icon">
                      {key === 0 ? <Icon1 /> : null}
                      {key === 1 ? <Icon2 /> : null}
                      {key === 2 ? <Icon3 /> : null}
                      {key === 3 ? <Icon4 /> : null}
                    </div>
                    <div className="name">{item.title}</div>
                  </Link>
                </div>
              )
              : null}
          </div>
        </div>
      </>
    )
  }
}