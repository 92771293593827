import * as deviceDetect from "react-device-detect";
import { APIClient } from './_api';
import { now } from "moment-jalaali";

function isOnline() {
  const condition = navigator.onLine ? 'online' : 'offline';
  var isonline = false;
  if (condition === 'online') {
    // const webPing = setInterval(
    //   () => {
    //     fetch('//google.com', {
    //       mode: 'no-cors',
    //       })
    //     .then(() => {
    //       this.setState({ isDisconnected: false }, () => {
    //         return clearInterval(webPing)
    //       });
    //     }).catch(() => this.setState({ isDisconnected: true }) )
    //   }, 2000);
    // return;
    isonline = true;
  } else {
    isonline = false;
  }

  return isonline;
}

function setLang() {
  var lang = localStorage.getItem("language");
  let currenURL = window.location.href.replace('https://', '').replace('http://', '').split('/');
  if (typeof currenURL[1] !== 'undefined' && currenURL[1].length == 2) {
    lang = currenURL[1].toLowerCase();
  } else {
    if (typeof lang === 'undefined' || lang === null || lang.length !== 2) {
      lang = 'fa';
    }
  }
  localStorage.setItem("language", lang);
  document.querySelector("html").setAttribute("lang", lang);
  var dir = "rtl";
  switch (lang) {
    case "en":
    case "in":
    case "cn":
      dir = "ltr";
      break;
  }
  document.querySelector("html").setAttribute("dir", dir);

}


function getQueryParams(qs) {

  var qsA = qs.split("?");
  qs = qsA[qsA.length - 1];
  qs = qs.split('+').join(' ');

  var params = {},
    tokens,
    re = /[?&]?([^=]+)=([^&]*)/g;

  while (tokens = re.exec(qs)) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }

  return params;
}

function webType() {
  var client = 'web';
  var isPWA = false;
  var forcePWA = deviceDetect.isIOS;
  forcePWA = 0;
  var params = getQueryParams(window.location.href);
  if (
    (params["src"] !== null && params["src"] == "pwa") ||
    //deviceDetect.isIOS ||
    forcePWA ||
    window.matchMedia('(display-mode: standalone)').matches ||
    window.navigator.standalone === true ||
    document.referrer.includes('android-app://') ||
    navigator.userAgent.match(/MSAppHost/i)
  ) {
    client = "pwa";
    isPWA = true;
  }

  localStorage.setItem("client", client);
  localStorage.setItem("isPWA", isPWA);
  document.querySelector("html").setAttribute("client", client);
  return client;
}


function setPageTitle(mytitle=''){
  var path = window.location.pathname;
  var pathA = path.split('/');
  var title = "";
  var lang = pathA[1];
  var words =  getAllWords();
  var page = pathA[1];
  if(pathA.length > 2){
    page = pathA[2];
  }
  if(lang == ""){
    lang = localStorage.getItem("language");
  }
  if(words === null){
    return false;
  }
  var sitetitle = words[lang]['SiteTitle'] ?  words[lang]['SiteTitle'] : "Jey Oil Refining CO, Iran Bitumen, Drum, Iran high quality bitumen";
  
  switch(page){
    case "":
    case "home":
      title = "";
      break;

      case "news":
      title = words[lang]['news'];
      break;

      case "supplies":
      title = words[lang]['supplies'];
      break;

      case "products":
      title = words[lang]['products'];
      break;

      case "projects":
      title = words[lang]['projects'];
      break;

      case "page":
        title = pathA[3];
      break;

  }

  if(mytitle !== ''){
    title = mytitle;
  }

  if(page != "" && page != "home"){
    document.querySelector('meta[name="keywords"]').innerHTML = title +", "+ sitetitle;
    document.querySelector('meta[name="description"]').innerHTML = title +". "+ sitetitle;
  }
  if(title !== ""){
    title += " - ";
  }
  document.querySelector("title").innerHTML = title + sitetitle;
  
}

function axiosConfig() {
  let axiosConfig = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "X-Requested-With"
    }
  };

  return axiosConfig;
}

async function wget(link, client = null, cache = true) {
  var res = null;
  if (client == null) {
    client = localStorage.getItem("client");
  }
  var lang = localStorage.getItem("language");
  link = link.replace("{client}", client);
  link = link.replace("{lang}", lang);
  var linkNameA = link.split('?');
  var pageName = linkNameA[0];
  var parms = linkNameA[1] ? linkNameA[1] : "";
  var linkName = lang + "" + linkNameA[0] + "?" + parms;
  var d = new Date();
  var date = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " +
    d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();

  if (isOnline()) {
    var LocalRes = localStorage.getItem(linkName) ? JSON.parse(localStorage.getItem(linkName)) : null;
    var difference = now();
    if (LocalRes && LocalRes.headers.date !== null) {
      difference = now() - (new Date(LocalRes.headers.date).getTime());
    }
    if (cache && LocalRes !== null && difference < (0.01 * 60 * 60 * 1000)) {
      res = localStorage.getItem(linkName);
      res = JSON.parse(res);
    } else {
      res = await APIClient.get(link, axiosConfig());
    }
  } else {
    if (localStorage.getItem(linkName) === null) {
      res = null
    } else {
      res = localStorage.getItem(linkName);
      res = JSON.parse(res);
    }
  }

  if (res != null) {
    res.headers.date = date;
    localStorage.setItem(linkName, JSON.stringify(res));

  }
  return res;
}

function getPage(link, client = null) {
  var res = wget(`/desktop/pages/get?link=${link}&offset=0&count=20&client={client}&lang={lang}`);
  return res;
}

function getCatalog(client = null) {
  var res = wget(`/desktop/catalogs/main?offset=0&count=20&client={client}&lang={lang}`);
  return res;
}

function getCatalogId(id, client = null) {
  var res = wget(`/desktop/catalogs/${id}?offset=0&count=20&client={client}&lang={lang}`);
  return res;
}

function getLogo(client = null) {
  var res = wget(`/desktop/config/get?client={client}&lang={lang}`);
  return res;
}

function getConfig(cache = true) {
  var res = wget(`/desktop/config/get?client={client}&lang={lang}`, webType(), cache);
  return res;
}
function getMobileHome(cache = true) {
  var res = wget(`/android/home?client={client}&lang={lang}`, webType(), cache);
  return res;
}
function getMainMenu(cache = true) {
  var res = wget(`/desktop/menus/main?client={client}&lang={lang}`, webType(), cache);
  return res;
}
function getSupplies(cache = true, offset = 0, count = 25) {
  var res = wget(`/desktop/supplies/recent?client={client}&lang={lang}&offset=${offset}&count=${count}`, webType(), cache);
  return res;
}
function getProducts(cache = true, offset = 0, count = 25) {
  var res = wget(`/desktop/products/recent?client={client}&lang={lang}&offset=${offset}&count=${count}`, webType(), cache);
  return res;
}
function getProjects(cache = true, offset = 0, count = 25) {
  var res = wget(`/desktop/projects/recent?client={client}&lang={lang}&offset=${offset}&count=${count}`, webType(), cache);
  return res;
}
function getNews(cache = false, offset = 0, count = 25) {
  var res = wget(`/desktop/news/recent?client={client}&lang={lang}&offset=${offset}&count=${count}`, webType(), cache);
  return res;
}


function SendForm(name, email, message,type='message') {
  return APIClient.post(`/global/senContact?name=${name}&email=${email}&message=${message}&type=${type}&lang=&client=`, axiosConfig());
}


function SendPoll(data = {}) {
  var dataS = "";
  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      dataS += "&" + key + "=" + data[key];
    }
  }
  return APIClient.post(`/global/sendPoll?lang=${localStorage.getItem("language")}&client=${localStorage.getItem("client")}`, data, axiosConfig());
}

function SendOrder(data = {}) {
  var dataS = "";
  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      dataS += "&" + key + "=" + data[key];
    }
  }
  return APIClient.post(`/global/sendRequest?lang=${localStorage.getItem("language")}&client=${localStorage.getItem("client")}`, data, axiosConfig());
}

function SendDowloadLog(data = {}) {
  var dataS = "";
  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      dataS += "&" + data[key]["name"] + "=" + data[key]["value"];
    }
  }
  return APIClient.post(`/global/sendDownloadLog?lang=${localStorage.getItem("language")}&client=${localStorage.getItem("client")}`+dataS, data, axiosConfig());
}



function getSlider(cache = true) {
  var res = null;
  var linkName = '/desktop/slider/home';
  if (cache && localStorage.getItem(linkName) !== null) {
    res = localStorage.getItem(linkName);
    res = JSON.parse(res);
  } else {
    res = wget(linkName + `?client={client}&lang={lang}`);
  }


  return res;
}


function getContact(cache = true) {
  var res = wget(`global/info?client={client}&lang={lang}`, webType(), cache);
  return res;
}

function getMainGallery(offset = 0, count = 20) {
  return wget(`/desktop/gallery/main?client={client}&offset=${offset}&count=${count}&lang={lang}`);
}

function getGalleryById(id) {
  return wget(`/desktop/gallery/${id}?client={client}&offset=0&count=500&lang={lang}`);
}

function getGalleryList(offset = 0, count = 10) {
  return wget(`/desktop/gallery/recent?client={client}&offset=${offset}&count=${count}&lang={lang}`);
}

function getAllWords() {
  return localStorage.getItem('Words') ? JSON.parse(localStorage.getItem('Words')) : null;
}

function getDescktopMainMenu() {
  return wget(`/desktop/menus/main?client={client}&offset=0&count=500&lang={lang}`);
}
function getDescktopUnderSlider() {
  return wget(`/desktop/menus/under-slider?client={client}&offset=0&count=500&lang={lang}`);
}
function getNew(id) {
  return wget(`/desktop/news/${id}?client={client}&offset=0&count=500&lang={lang}`);
}

function getNewAttachment(id) {
  return wget(`/desktop/news/${id}/attachments?client={client}&offset=0&count=500&lang={lang}`);
}
function getRecentNews(offset = 0, count = 10, cat=0) {
  return wget(`/desktop/news/recent?client={client}&offset=${offset}&count=${count}&lang={lang}&cat=${cat}`);
}

function getProject(id) {
  return wget(`/desktop/projects/${id}?client={client}&offset=0&count=500&lang={lang}`);
}
function getRecentProject(offset = 0, count = 10) {
  return wget(`/desktop/projects/recent?client={client}&offset=${offset}&count=${count}&lang={lang}`);
}

function getProduct(id) {
  return wget(`/desktop/products/${id}?client={client}&offset=0&count=500&lang={lang}`);
}
function getRecentProduct(offset = 0, count = 10) {
  return wget(`/desktop/products/recent?client={client}&offset=${offset}&count=${count}&lang={lang}`);
}
function getHomeSlider() {
  return wget(`/desktop/slider/home?client={client}&lang={lang}`);
}

function getSupply(id) {
  return wget(`/desktop/supplies/${id}?client={client}&offset=0&count=500&lang={lang}`);
}

function getRecentSupplies(offset = 0, count = 10) {
  return wget(`/desktop/supplies/recent?client={client}&offset=${offset}&count=${count}&lang={lang}`);
}
function getCompanyUnit(id) {
  return wget(`/desktop/company-units/${id}?client={client}&offset=0&count=500&lang={lang}`);
}

function getRecentCompanyUnits(offset = 0, count = 10) {
  return wget(`/desktop/company-units/recent?client={client}&offset=${offset}&count=${count}&lang={lang}`);
}
function getRecentCompanyUnitsCats(offset = 0, count = 10) {
  return wget(`/desktop/unit-categories/recent?client={client}&offset=${offset}&count=${count}&lang={lang}`);
}
function getRecentCompanyUnitsByCat(cat=0, offset = 0, count = 10) {
  return wget(`/desktop/unit-categories/${cat}?client={client}&offset=${offset}&count=${count}&lang={lang}`);
}
export {
  isOnline,
  getPage,
  getLogo,
  getCatalog,
  getCatalogId,
  getConfig,
  getSlider,
  getMobileHome,
  getMainMenu,
  getSupplies,
  getProducts,
  getProjects,
  getNews,
  getNew,
  getNewAttachment,
  SendForm,
  SendPoll,
  SendOrder,
  SendDowloadLog,
  setLang,
  getQueryParams,
  webType,
  getContact,
  getMainGallery,
  getGalleryById,
  getGalleryList,
  getAllWords,
  getDescktopMainMenu,
  getDescktopUnderSlider,
  getRecentNews,
  getProduct,
  getRecentProduct,
  getProject,
  getRecentProject,
  getHomeSlider,
  getSupply,
  getRecentSupplies,
  getCompanyUnit,
  getRecentCompanyUnits,
  setPageTitle,
  getRecentCompanyUnitsCats,
  getRecentCompanyUnitsByCat
};