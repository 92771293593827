import React, { Component } from "react";

// Service
import * as Service from '../../../api/common';

// Components
import Icon from './company';
import Title from '../../../components/title';
import ReactHtmlParser from 'react-html-parser';
import Layout from "../../../components/inner-layout";
import { LangContext } from '../../../App/languageContext';
import { Link } from 'react-router-dom';
import "./index.css";

const CurrentLocale = localStorage.getItem("language");

class AboutCompany extends Component {
  state = {
    item: "",
    componentName: ""
  }

  componentDidMount() {
    this.getItem(this.props.match.params.name);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.name !== this.props.match.params.name) {
      this.getItem(this.props.match.params.name);
    }
  }


  getItem = async (name) => {
    try {
      const { data } = await Service.getPage(`${CurrentLocale}/page/${name}`)
      this.setState(preveState => ({
        item: data.result
      }))
    } catch (error) {
      console.error(error);
    }
  }


  extractJs(str=''){
    var js = str.match(/<script.*?>(.*?)<\/script>/i);
    var res = null;
    if(js !== null && typeof js[1] !== 'undefined' ) {
      res = '';
      res += js[1];
      res += '';
    }

    const script = document.createElement("script");
    script.innerHTML = res;
    document.body.appendChild(script);

  }

  renderHTML(rawHTML) {
    var res = React.createElement("script", { dangerouslySetInnerHTML: { __html: rawHTML } });
    return res;
  } 


  render() {
    const item = this.state.item;
    Service.setPageTitle(item.title ? item.title : '');
    

    return (
      <>
        <div className="bread_crumb">
          <div className="row text-center content">
            <div className="page_title">
              <Icon />
              <span>{item.title ? item.title : ''}</span>
            </div>
            <ul className="br-menu">
              <li><Link to={`/${CurrentLocale}/home`}><span className="mdi mdi-home-outline"></span></Link></li>
              <li>
                <span>{item.title ? item.title : ''}</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="page-content">
          <div className="row columns">
            <Title icon="domain">{item.title ? item.title : ''}</Title>
            {item ? ReactHtmlParser(item.body) : null}
          </div>
        </div>


         { this.extractJs(item.body)}


      </>
    )
  }
}
AboutCompany.contextType = LangContext;
export default Layout(AboutCompany)