import React, { Component } from "react";

// Service
import * as Service from '../../../api/common';

// Components
import Title from '../../../components/title';
import ReactHtmlParser from 'react-html-parser';
import { LangContext } from '../../../App/languageContext';
import { Link } from 'react-router-dom';
import LargeSlider from '../../../components/slider-large';
import PwaHeader from '../../../components/pwa-header';
import PwaFooter from '../../../components/pwa-footer';
import PwaNavHeader from '../../../components/pwa-nav-header/index';
import * as Yup from 'yup';
import Form from '../../../components/form';
import { Formik } from 'formik';
import loader from "../../../components/loader";
import Loading from '../../../container/site/other/loading';
import Layout from "../../../components/inner-layout";

const CurrentLocale = localStorage.getItem("language");

class Order extends Component {
  state = {
    item: "",
    isLoading: true,
    words: Service.getAllWords(),
    lang: localStorage.getItem("language"),
    initialValues: {
      customer_type: '',
      company: '',
      reason: '',
      grade: '',
      quantity: '',
      customer_country: '',
      customer_address: '',
      customer_required_docs: '',
      note: ''
    }

  }

  componentDidMount() {
    this.setState({ isLoading: false });
  }


  handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      const { data } = await Service.SendOrder(values)
      alert(data.result)
      resetForm();
    } catch (error) {
      console.error(error);
    }
    setSubmitting(false);
  };

  render() {
    const item = this.state.item;
    const loading = this.state.isLoading;
    if (loading) {
      return (
        <Loading />
      );
    } else {
      return (
        <>

          {/* مسیر سایت */}
          <div className="bread_crumb">
            <div className="row text-center content">

              <ul className="br-menu">
                <li><Link to={`/${CurrentLocale}/home`}><span className="mdi mdi-home-outline"></span></Link></li>
                <li><span>{this.context.sample_product_order}</span></li>
              </ul>
            </div>
          </div>
          {/*  */}

          <div className="home_top_section pos-r home_section" >
            <div className="page-content page-Order">
              <div className="row columns">
                <Title icon="cart-plus">{this.state.words[this.state.lang]["sample_product_order"]}</Title>

                <Formik
                  enableReinitialize
                  initialValues={this.state.initialValues}
                  onSubmit={this.handleSubmit}
                  render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                  }) => (
                      <Form
                        onSubmit={handleSubmit}
                        loading={isSubmitting}

                      >
                        <div className="send-message-form column medium-12 large-12 small-12 ">

                          <div className="row small-12">

                            <div className="column small-6">
                              <input
                                type="radio"
                                className="winput"
                                autoComplete="off"
                                required="required"
                                name="customer_type"
                                value={this.state.words[this.state.lang]["existing_customer"]}
                                onBlur={handleBlur}
                                error={touched.customer_type && errors.customer_type}
                                onChange={handleChange}
                              />
                              <span> {this.state.words[this.state.lang]["existing_customer"]}
                              </span>
                            </div>


                            <div className="column small-6">
                              <input
                                type="radio"
                                className="winput"
                                autoComplete="off"
                                required="required"
                                name="customer_type"
                                value={this.state.words[this.state.lang]["new_customer"]}
                                onBlur={handleBlur}
                                error={touched.customer_type && errors.customer_type}
                                onChange={handleChange}
                              />
                              <span> {this.state.words[this.state.lang]["new_customer"]}
                              </span>
                            </div>


                          </div>


                          <div className="input-name-contact">
                            <input
                              type="text"
                              className="winput"
                              autoComplete="off"
                              required="required"
                              name="company"
                              value={values.company}
                              onBlur={handleBlur}
                              error={touched.company && errors.company}
                              onChange={handleChange}
                              placeholder={this.state.words[this.state.lang]["company_name"]}
                            />
                          </div>

                          <div className="input-name-contact">
                            <input
                              type="text"
                              className="winput"
                              autoComplete="off"
                              required=""
                              name="email"
                              value={values.email}
                              onBlur={handleBlur}
                              error={touched.email && errors.email}
                              onChange={handleChange}
                              placeholder={this.state.words[this.state.lang]["email"]}
                            />
                          </div>

                          <div className="input-name-contact">
                            <input
                              type="text"
                              className="winput"
                              autoComplete="off"
                              required=""
                              name="phone"
                              value={values.phone}
                              onBlur={handleBlur}
                              error={touched.phone && errors.phone}
                              onChange={handleChange}
                              placeholder={this.state.words[this.state.lang]["phone"]}
                            />
                          </div>
                          
                          <div className="input-name-contact">
                            <input
                              type="text"
                              className="winput"
                              autoComplete="off"
                              required="required"
                              name="reason"
                              value={values.reason}
                              onBlur={handleBlur}
                              error={touched.reason && errors.reason}
                              onChange={handleChange}
                              placeholder={this.state.words[this.state.lang]["reason_requested"]}
                            />
                          </div>



                          <div className="input-name-contact">
                            <input
                              type="text"
                              className="winput"
                              autoComplete="off"
                              required="required"
                              name="grade"
                              value={values.grade}
                              onBlur={handleBlur}
                              error={touched.grade && errors.grade}
                              onChange={handleChange}
                              placeholder={this.state.words[this.state.lang]["sample_grade"]}
                            />
                          </div>


                          <div className="input-name-contact">
                            <input
                              type="number"
                              className="winput"
                              autoComplete="off"
                              required="required"
                              name="quantity"
                              value={values.quantity}
                              onBlur={handleBlur}
                              error={touched.quantity && errors.quantity}
                              onChange={handleChange}
                              placeholder={this.state.words[this.state.lang]["value_requested"]}
                            />
                          </div>

                          <textarea
                            type="text"
                            className="winput"
                            autoComplete="off"
                            required="required"
                            name="customer_address"
                            value={values.customer_address}
                            onBlur={handleBlur}
                            error={touched.customer_address && errors.customer_address}
                            onChange={handleChange}
                            rows="3"
                            placeholder={this.state.words[this.state.lang]["customer_address"]}
                          ></textarea>

                          <div className="input-name-contact">
                            <input
                              type="text"
                              className="winput"
                              autoComplete="off"
                              required="required"
                              name="customer_country"
                              value={values.customer_country}
                              onBlur={handleBlur}
                              error={touched.customer_country && errors.customer_country}
                              onChange={handleChange}
                              placeholder={this.state.words[this.state.lang]["customer_country"]}
                            />
                          </div>


                          <label>{this.state.words[this.state.lang]["document_requested"]}:</label>
                          <div className="input-name-contact">
                            <div className="column small-4">
                              <input
                                type="checkbox"
                                className="winput"
                                autoComplete="off"
                                required=""
                                name="customer_required_docs"
                                value={this.state.words[this.state.lang]["msds"]}
                                onBlur={handleBlur}
                                error={touched.customer_required_docs && errors.customer_required_docs}
                                onChange={handleChange}
                              />
                              <label> {this.state.words[this.state.lang]["msds"]}</label>
                            </div>
                            <div className="column small-4">
                              <input
                                type="checkbox"
                                className="winput"
                                autoComplete="off"
                                required=""
                                name="customer_required_docs"
                                value={this.state.words[this.state.lang]["packing_list"]}
                                onBlur={handleBlur}
                                error={touched.customer_required_docs && errors.customer_required_docs}
                                onChange={handleChange}
                              />
                              <label> {this.state.words[this.state.lang]["packing_list"]}</label>
                            </div>
                            <div className="column small-4">
                              <input
                                type="checkbox"
                                className="winput"
                                autoComplete="off"
                                required=""
                                name="customer_required_docs"
                                value={this.state.words[this.state.lang]["sample_invoice"]}
                                onBlur={handleBlur}
                                error={touched.customer_required_docs && errors.customer_required_docs}
                                onChange={handleChange}
                              />
                              <label> {this.state.words[this.state.lang]["sample_invoice"]}</label>
                            </div>
                          </div>


                          <textarea
                            type="text"
                            className="winput"
                            autoComplete="off"
                            required="required"
                            name="note"
                            value={values.note}
                            onBlur={handleBlur}
                            error={touched.note && errors.note}
                            onChange={handleChange}
                            rows="5"
                            placeholder={this.state.words[this.state.lang]["other_note"]}
                          ></textarea>



                          <div className="row small-12 text-left">
                            <button className="btn-send-msg-new" type="submit">{this.state.words[this.state.lang]["send"]}</button>
                          </div>
                        </div>
                      </Form>
                    )}
                />
              </div>
            </div>
          </div>

        </>
      )
    }
  }
}
Order.contextType = LangContext;
export default Layout(Order)