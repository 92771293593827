import React from "react";

export default () => (
  <svg viewBox="0 0 341.333 341.333">
    <rect x="128" y="128" width="85.333" height="85.333" />
    <rect x="0" y="0" width="85.333" height="85.333" />
    <rect x="128" y="256" width="85.333" height="85.333" />
    <rect x="0" y="128" width="85.333" height="85.333" />
    <rect x="0" y="256" width="85.333" height="85.333" />
    <rect x="256" y="0" width="85.333" height="85.333" />
    <rect x="128" y="0" width="85.333" height="85.333" />
    <rect x="256" y="128" width="85.333" height="85.333" />
    <rect x="256" y="256" width="85.333" height="85.333" />
  </svg>
)