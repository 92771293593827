import React, { Component } from "react";

// Services
import * as Service from '../../../api/common';


// Components
import { Link } from "react-router-dom";
import Layout from "../../../components/inner-layout";
import Pagination from '../../../components/pagination';
import { LangContext } from '../../../App/languageContext';
import Loading from '../other/loading';

import "./index.css";

import Icon1 from "../../../assets/svg/link";
import Icon4 from "../../../assets/svg/product";
const CurrentLocale = localStorage.getItem("language");

class ProductList extends Component {
  state = {
    AllData: [],
    isLoading: true,
    currentBlogs: [],
    currentPage: null,
    totalPages: null
  };

  componentDidMount() {
    this.getList()
    const AllData = this.state.AllData;
    this.setState({ AllData });
  }

  getList = async () => {
    try {
      const { data } = await Service.getRecentProduct(0, 1000);
      this.setState({
        AllData: data.result,
        isLoading: false
      })
    } catch (error) {
      console.error(error);
    }
  }

  onPageChanged = data => {
    const { AllData } = this.state;
    const { currentPage, totalPages, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    const currentBlogs = AllData.slice(offset, offset + pageLimit);
    this.setState({ currentPage, currentBlogs, totalPages });
  };

  render() {
    Service.setPageTitle();
    const {
      AllData,
      currentBlogs,
    } = this.state;
    const totalCountries = AllData.length;

    if (totalCountries === 0) return null;

    console.log(this.state.isLoading);
    if(this.state.isLoading){
      return (
        <Loading />
      );
    }else{
    return (
      <>
        {/* مسیر سایت */}
        <div className="bread_crumb">
          <div className="row text-center content">
            <div className="page_title"><Icon4 /><span>{this.context.products}</span></div>
            <ul className="br-menu">
              <li><Link to={`/${CurrentLocale}/home`}><span className="mdi mdi-home-outline"></span></Link></li>
              <li><span>{this.context.products}</span></li>
            </ul>
          </div>
        </div>
        <div className="page-content">
          {/* لیست محصول */}
          <div className="row">
            {currentBlogs ?
              currentBlogs.map((item, key) => (
                <div className="large-3 medium-6 columns parent_product_item" key={key}>
                  <Link className="product_item_List" to={`/${CurrentLocale}/products/${item.id}/${item.title}`}>
                    <div className="container-img">
                      <img
                        className="image image-pro"
                        src={item.img}
                        alt={item.title}
                        title={item.title}
                      />
                      <div className="icon-more">
                        <Icon1 />
                      </div>
                    </div>
                    <div className="dis">
                      <i className="mdi mdi-chevron-double-left"></i>&nbsp;
                        <span>{item.title}</span>
                    </div>
                  </Link>
                </div>
              ))
              : null}
          </div>

          <div className="row columns text-center">
            <Pagination
              totalRecords={totalCountries}
              pageLimit={12}
              pageNeighbours={1}
              onPageChanged={this.onPageChanged}
            />
          </div>

        </div>
      </>
    );
    }
  }
}
ProductList.contextType = LangContext;
export default Layout(ProductList);
