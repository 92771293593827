import React, { Component } from "react";

// Services
import * as Service from '../../../api/common';

// Components
import Icon4 from "../../../assets/svg/news";
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import Layout from "../../../components/inner-layout";
import { LangContext } from '../../../App/languageContext';

import LargeSlider from '../../../components/slider-large';
import PwaHeader from '../../../components/pwa-header';
import PwaFooter from '../../../components/pwa-footer';
import PwaNavHeader from '../../../components/pwa-nav-header/index';

const CurrentLocale = localStorage.getItem("language");

class SuppliesItem extends Component {

  state = {
    item: ""
  }

  componentDidMount() {
    this.get_item_by_id(this.props.match.params.id)
  }

  get_item_by_id = async (id) => {
    try {
      const { data } = await Service.getSupply(id);
      this.setState({
        item: data.result
      })
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const item = this.state.item;
    return (
      <>
        <div className="home_top_section pos-r home_section" >
          <PwaHeader client={this.props.client} />
          <LargeSlider client={this.props.client} />
          <PwaNavHeader />

          <div className="page-content page-suppliy">
            <div className="row columns">
              <div className="news_item mb-15">
                <div className="news-img mb-30 text-center">
                  <img src={item.img} alt={item.title} />
                </div>
                <div className="news-info">
                  <h1>{item.title}</h1>
                  {/* <div className="blog_intro">
                  <span>
                    <i className="mdi mdi-grease-pencil"></i>&nbsp;{this.context.postedIn}&nbsp;عرضه ها
                  </span>
                  <span>
                    <i className="mdi mdi-comment-outline"></i>&nbsp;0&nbsp;{this.context.comment}
                  </span>
                  <span>
                    <i className="mdi mdi-heart-outline"></i>&nbsp;0&nbsp;{this.context.like}
                  </span>
                </div> */}
                </div>
                {item ? ReactHtmlParser(item.body) : null}
              </div>
            </div>


          </div>
        </div>
        <PwaFooter />
      </>
    );
  }
}
SuppliesItem.contextType = LangContext;
export default (SuppliesItem);
