import React, { Component } from "react";

// Service
import * as Service from '../../../api/common';

// Components
import Icon from './company';
import Title from '../../../components/title';
import ReactHtmlParser from 'react-html-parser';
import Layout from "../../../components/inner-layout";
import { LangContext } from '../../../App/languageContext';
import { Link } from 'react-router-dom';

import LargeSlider from '../../../components/slider-large';
import PwaHeader from '../../../components/pwa-header';
import PwaFooter from '../../../components/pwa-footer';
import PwaNavHeader from '../../../components/pwa-nav-header/index';
import Loading from '../../../container/site/other/loading';



const CurrentLocale = localStorage.getItem("language");

class AboutCompany extends Component {
  state = {
    item: "",
    componentName: ""
  }

  componentDidMount() {
    this.getItem(this.props.match.params.lang,this.props.match.params.name);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.name !== this.props.match.params.name) {
      this.getItem(this.props.match.params.lang,this.props.match.params.name);
    }
  }


  getItem = async (lang, name) => {
    try {
      console.log(lang);
      const { data } = await Service.getPage(`${lang}/page/${name}`)
      this.setState(preveState => ({
        item: data.result
      }))
    } catch (error) {
      console.error(error);
    }
  }


  extractJs(str = '') {
    var js = str.match(/<script.*?>(.*?)<\/script>/i);
    var res = null;
    if (js !== null && typeof js[1] !== 'undefined') {
      res = '';
      res += js[1];
      res += '';
    }

    const script = document.createElement("script");
    script.innerHTML = res;
    document.body.appendChild(script);

  }

  renderHTML(rawHTML) {
    var res = React.createElement("script", { dangerouslySetInnerHTML: { __html: rawHTML } });
    return res;
  }


  render() {
    const item = this.state.item;

    return (
      <>

        <div className="home_top_section pos-r home_section" >
          <PwaHeader client={this.props.client} />
          <LargeSlider client={this.props.client} />
          <PwaNavHeader />
          <div className="main-page-news">
            <div className="page-content">
              <div className="">
                <Title icon="domain">{item.title ? item.title : ''}</Title>
                {item ? ReactHtmlParser(item.body) : null}
              </div>
            </div>


          </div>




        </div>

        {this.extractJs(item.body)}

        <PwaFooter />
      </>
    )
  }
}
AboutCompany.contextType = LangContext;
export default AboutCompany;