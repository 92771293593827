import React, { Component } from "react";

// Service
import * as Service from '../../../api/common';

// Components
import Icon from './company';
import Title from '../../../components/title';
import ReactHtmlParser from 'react-html-parser';
import Layout from "../../../components/inner-layout";
import { LangContext } from '../../../App/languageContext';
import { Link } from 'react-router-dom';
import Spinner from "../../../assets/images/spinner.gif";
import LargeSlider from '../../../components/slider-large';
import PwaHeader from '../../../components/pwa-header';
import PwaFooter from '../../../components/pwa-footer';
import PwaNavHeader from '../../../components/pwa-nav-header/index';


const CurrentLocale = localStorage.getItem("language");

class AboutCompany extends Component {
  state = {
    item: "",
    isLoading: true
  }

  componentDidMount() {
    this.getItem();
  }

  getItem = async () => {
    try {
      const { data } = await Service.getPage(`${CurrentLocale}/about-our-company`)
      this.setState({
        item: data.result,
        isLoading: false
      })
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const item = this.state.item;
    const loading = this.state.isLoading;
    if (loading) {
      return (
        <div className="loading-apge">
          <img src={Spinner} alt="spinner" />
        </div>
      );
    } else {
      return (
        <>

          <div className="home_top_section pos-r home_section" >
            <PwaHeader client={this.props.client} />
            <LargeSlider client={this.props.client} />
            <PwaNavHeader />

            <div className="page-content page-about-our-company">
              <div className="row columns">
                <Title icon="domain">{item.title ? item.title : this.context.introductionCompany}</Title>
                {item ? ReactHtmlParser(item.body) : null}
              </div>
            </div>
          </div>
          <PwaFooter />

        </>
      )
    }
  }
}
AboutCompany.contextType = LangContext;
export default (AboutCompany)