
import React, { Component } from "react";

import * as Service from '../../api/common';


import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";

// slider images
// import Img1 from '../../assets/images/slider/img1.png';
// import Img2 from '../../assets/images/slider/img2.png';
// import Img3 from '../../assets/images/slider/img3.png';

import '../../assets/css/animate.css';
import './index.css';
class SmallSlider extends Component {
  state = {
    options: {
      items: 1,
      dots: false,
      nav: false,
      rewind: true,
      rtl: true,
      mouseDrag: false,
      touchDrag: false,
      loop: true,
      autoplay: true,
      autoplayTimeout: 4000,
      autoplayHoverPause: false,
      smartSpeed: 600,
      animateOut: 'fadeOut',
      animateIn: 'fadeIn'
    },
    slider: ""
  }

  componentDidMount() {
    this.getSlider()
  }
  getSlider = async () => {
    try {
      const { data } = await Service.getSlider(true);
      this.setState({ slider: data.result })
    } catch (error) {
      console.error(error);
    }
  }


  render() {
    return (
      <>
        <div className="small_slider ">
          {this.state.slider ?
            <OwlCarousel ref="slider1" options={this.state.options}>
              {this.state.slider ?
                this.state.slider.map((item, key) => (
                  <div className="item" key={key}>
                    <img src={item.img} alt={item.title} />
                  </div>
                )) : null}
            </OwlCarousel>
            : null}
        </div>

      </>
    )
  }
}
export default SmallSlider;
