import React from "react";

export default () => (
  <svg viewBox="0 0 512 512">

		<path d="M485.097,80.1H291.945c-3.864,0-7.611-1.394-10.544-3.92l-35.432-30.566c-6.843-5.894-15.578-9.14-24.596-9.14H94.423
			c-14.834,0-26.903,12.069-26.903,26.903v35.957H26.903C12.069,99.334,0,111.403,0,126.237v322.386
			c0,14.834,12.069,26.903,26.903,26.903h390.673c14.834,0,26.903-12.069,26.903-26.903v-35.949h40.618
			c14.834,0,26.903-12.069,26.903-26.903V107.003C512,92.168,499.931,80.1,485.097,80.1z M422.957,448.623
			c0,2.967-2.414,5.381-5.381,5.381H26.903c-2.967,0-5.381-2.414-5.381-5.381V227.428h401.434V448.623z M422.957,205.906H21.522
			v-79.668c0-2.967,2.414-5.381,5.381-5.381h126.943c3.869,0,7.612,1.391,10.542,3.919l35.434,30.559
			c6.836,5.895,15.571,9.141,24.598,9.141h193.156c2.967,0,5.381,2.414,5.381,5.381V205.906z M490.478,385.771
			c0,2.967-2.414,5.381-5.381,5.381h-40.618V169.856c0-14.834-12.069-26.903-26.903-26.903H224.42
			c-3.869,0-7.614-1.391-10.543-3.918l-35.433-30.557c-6.836-5.896-15.571-9.143-24.598-9.143H89.042V63.377
			c0-2.967,2.414-5.381,5.381-5.381h126.95c3.864,0,7.611,1.394,10.544,3.92l35.432,30.566c6.843,5.893,15.578,9.139,24.596,9.139
			h193.153c2.967,0,5.381,2.414,5.381,5.381V385.771z"/>

		<path d="M380.144,264.111h-64.445c-5.943,0-10.761,4.818-10.761,10.761c0,5.942,4.818,10.761,10.761,10.761h64.445
			c5.943,0,10.761-4.819,10.761-10.761C390.905,268.928,386.088,264.111,380.144,264.111z"/>

		<path d="M380.144,315.764h-64.445c-5.943,0-10.761,4.819-10.761,10.761s4.818,10.761,10.761,10.761h64.445
			c5.943,0,10.761-4.819,10.761-10.761S386.088,315.764,380.144,315.764z"/>
	</svg>
)