import React, { Component } from "react";

// Services
import * as Service from '../../../api/common';


// Components
import Icon from './units';
import { Link } from "react-router-dom";
import Icon1 from "../../../assets/svg/link";
import Layout from "../../../components/inner-layout";
import Pagination from '../../../components/pagination';
import { LangContext } from '../../../App/languageContext';
import Loading from '../other/loading';
import LargeSlider from '../../../components/slider-large';
import PwaHeader from '../../../components/pwa-header';
import PwaFooter from '../../../components/pwa-footer';
import PwaNavHeader from '../../../components/pwa-nav-header/index';

const CurrentLocale = localStorage.getItem("language");

class UnitsList extends Component {

  state = {
    AllData: [],
    isLoading: true,
    currentBlogs: [],
    currentPage: null,
    totalPages: null
  };

  componentDidMount() {
    this.getList()
    const AllData = this.state.AllData;
    const isLoading = this.state.isLoading;
    this.setState({ AllData, isLoading });
  }

  getList = async () => {
    try {
      const { data } = await Service.getRecentCompanyUnits(0, 1000);
      this.setState({
        AllData: data.result,
        isLoading: false
      })
    } catch (error) {
      console.error(error);
    }
  }

  onPageChanged = data => {
    const { AllData } = this.state;
    const { currentPage, totalPages, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    const currentBlogs = AllData.slice(offset, offset + pageLimit);
    this.setState({ currentPage, currentBlogs, totalPages });
  };

  render() {
    const {
      AllData,
      currentBlogs,
    } = this.state;
    const totalCountries = AllData.length;

    if (totalCountries === 0) return null;

    if (this.state.isLoading) {
      return (
        <Loading />
      );
    } else {
      return (
        <>
          <div className="home_top_section pos-r home_section" >
            <PwaHeader client={this.props.client} />
            <LargeSlider client={this.props.client} />
            <PwaNavHeader />

            <div className="page-content page-company-units">
              {/* لیست واحدها */}
              <div className="row">
                {currentBlogs ?
                  currentBlogs.map((item, key) => (
                    <div className="large-3 medium-6 columns parent_product_item" key={key}>
                      <Link className="product_item_List" to={`/${CurrentLocale}/company-units/${item.id}/${item.title}`}>
                        <div className="container-img">
                          <img
                            className="image image-pro"
                            src={item.img}
                            alt={item.title}
                            title={item.title}
                          />
                          <div className="icon-more">
                            <Icon1 />
                          </div>
                        </div>
                        <div className="dis">
                          <i className="mdi mdi-chevron-double-left"></i>&nbsp;
                        <span>{item.title}</span>
                        </div>
                      </Link>
                    </div>
                  ))
                  : null}
              </div>

              <div className="row columns text-center">
                <Pagination
                  totalRecords={totalCountries}
                  pageLimit={12}
                  pageNeighbours={1}
                  onPageChanged={this.onPageChanged}
                />
              </div>

            </div>
          </div>
          <PwaFooter />
        </>
      );
    }
  }
}
UnitsList.contextType = LangContext;
export default (UnitsList)