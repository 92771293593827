
import React, { Component } from "react";

import * as Service from '../../api/common';
import InnerPopup from '../inner-popup/index';

import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import $ from 'jquery';
// slider images
// import Img1 from '../../assets/images/slider/img1.png';
// import Img2 from '../../assets/images/slider/img2.png';
// import Img3 from '../../assets/images/slider/img3.png';

import '../../assets/css/animate.css';
import './index.css';
class LargeSlider extends Component {
  state = {
    options: {
      items: 1,
      dots: false,
      nav: false,
      rewind: true,
      rtl: true,
      mouseDrag: true,
      touchDrag: true,
      loop: true,
      autoplay: true,
      autoplayTimeout: 4000,
      autoplayHoverPause: false,
      smartSpeed: 450,
      animateOut: 'fadeOut',
      animateIn: 'fadeIn'
    },
    images: [
      // {
      //   img: Img1,
      //   title: "img1"
      // },
      // {
      //   img: Img2,
      //   title: "img2"
      // },
      // {
      //   img: Img3,
      //   title: "img3"
      // }
    ],
    slider: [],
    isLoading: true
  }

  componentDidMount() {
    this.getSlider()
  }
  getSlider = async () => {
    try {
      const { data } = await Service.getSlider(true);
      this.setState({ slider: data.result, isLoading: false })
    } catch (error) {
      console.error(error);
    }
  }

  HidePopup(){
    $('.inner-popup').hide();
  }

  render() {
    return (
      <>
        <div className="large_slider " onClick={this.HidePopup}>
        <InnerPopup />
          {this.state.slider.length > 0 ?
            <OwlCarousel ref="slider1" options={this.state.options}>
              {this.state.slider.map((item, key) => (
                <div className="item" key={key} style={{ backgroundImage: `url(${item.img})` }}>
                  {/* <img src={item.img} alt={item.title} /> */}
                </div>
              ))}
            </OwlCarousel> : null}
            
        </div>
      </>
    )
  }
}
export default LargeSlider;
