import React, { Component } from "react";

// Services
// import * as Services from '../../../api/common';

// Components
import LargeSlider from '../../../components/slider-large';
import Header2 from '../../../components/header_otherLangs';
import Catalog from './catalog';
import Footer from '../../../components/footer';
import ArrowDown from '../../../assets/svg/arrow-down';
import AnchorLink from 'react-anchor-link-smooth-scroll'

import './index.css';


class Home extends Component {
  state = {

  }

  render() {
    return (
      <>
        <div className="home_top_section pos-r home_section">
          <Header2 />
          <LargeSlider />
          {/* فلش پایین اسلایدر */}
          <AnchorLink href="#catalogs" className="arrow">
            <ArrowDown />
          </AnchorLink>
        </div>

        <Catalog />

        <Footer />
      </>
    )
  }
}
export default Home;