import React from "react";
import SpinnerIMG from "../../../assets/images/spinner.gif";
import Spinner from '../../../components/loader';

export default ({text="J E Y   O I L"}) => (
    // <div className="loading-apge">
    //     <img src={SpinnerIMG} alt="Loading..." />
    // </div>

    <div className="loader-box">
        <Spinner text={text} />
    </div>

)