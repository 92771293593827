import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel2";

// Service
import * as Service from '../../api/common';

// Components
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';


export default class Footer extends Component {
  state = {
    contacts: {},
    socials: [],
    lang: localStorage.getItem("language"),
    options: {
      items: 1,
      dots: true,
      nav: false,
      rewind: true,
      rtl: false,
      mouseDrag: true,
      touchDrag: true,
      loop: true,
      autoplay: true,
      //autoplayTimeout: 4000,
      //autoplayHoverPause: true,
      smartSpeed: 600,
      animateOut: 'fadeOut',
      animateIn: 'fadeIn'
    },
  }

  constructor(props){
    super(props);
    if (this.state.lang == "fa") {
      this.state.options.rtl = true;
    }

  }
  componentDidMount() {
    this.getData()
  }

  getData = async () => {
    try {
      const { data } = await Service.getContact();
      this.setState({
        contacts: data.result.contacts,
        socials: data.result.socials
      })
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const props = this.props;
    const contact = this.state.contacts;
    return (
      <>
        {/* <div>
          <ul className={`c-information ${props.class ? props.class : ''}`}>
            <li>
              <span className="mdi mdi-email-outline"></span>
              <div className="c-text">{ReactHtmlParser(contact.email)}</div>
            </li>
            <li>
              <span className="mdi mdi-phone"></span>
              <div className="c-text">{contact.tel}</div>
            </li>
            <li>
              <span className="mdi mdi-printer"></span>
              <div className="c-text"><span dir="ltr">{contact.fax}</span></div>
            </li>
            <li>
              <span className="mdi mdi-map-marker-outline"></span>
              <div className="c-text"><span dir="ltr">{contact.address}</span></div>
            </li>
          </ul>
        </div> */}


        <div className="information-contact-us-new">

          <OwlCarousel ref="slider3" options={this.state.options}>
            <div className="item">
              <ul className={`c-information ${props.class ? props.class : ''}`}>
                <li>
                  <span className="mdi mdi-email-outline"></span>
                  <div className="c-text">{ReactHtmlParser(contact.email)}</div>
                </li>
                <li>
                  <span className="mdi mdi-phone"></span>
                  <div className="c-text">{contact.tel}</div>
                </li>
                <li>
                  <span className="mdi mdi-printer"></span>
                  <div className="c-text"><span dir="ltr">{contact.fax}</span></div>
                </li>
                <li>
                  <span className="mdi mdi-map-marker-outline"></span>
                  <div className="c-text"><span dir="ltr"><a href={"https://www.google.pl/maps/dir/" + contact.lat + "," + contact.lng + "/@" + contact.lat + "," + contact.lng} >{ReactHtmlParser(contact.address)}</a></span></div>
                </li>
              </ul>
            </div>


            <div className="item">
              <ul className={`c-information ${props.class ? props.class : ''}`}>
                {/* <li>
                  <span className="mdi mdi-email-outline"></span>
                  <div className="c-text">{ReactHtmlParser(contact.esfahan_email)}</div>
                </li> */}
                <li>
                  <span className="mdi mdi-phone"></span>
                  <div className="c-text">{contact.esfahan_tel}</div>
                </li>
                <li>
                  <span className="mdi mdi-printer"></span>
                  <div className="c-text"><span dir="ltr">{contact.esfahan_fax}</span></div>
                </li>
                <li>
                  <span className="mdi mdi-map-marker-outline"></span>
                  <div className="c-text"><span dir="ltr"><a href={"https://www.google.pl/maps/dir/" + contact.esfahan_lat + "," + contact.esfahan_lng + "/@" + contact.esfahan_lat + "," + contact.esfahan_lng} >{ReactHtmlParser(contact.esfahan_address)}</a></span></div>
                </li>
              </ul>
            </div>

            <div className="item">
              <ul className={`c-information ${props.class ? props.class : ''}`}>
                {/* <li>
                  <span className="mdi mdi-email-outline"></span>
                  <div className="c-text">{ReactHtmlParser(contact.bandarabas_email)}</div>
                </li> */}
                <li>
                  <span className="mdi mdi-phone"></span>
                  <div className="c-text">{contact.bandarabas_tel}</div>
                </li>
                <li>
                  <span className="mdi mdi-printer"></span>
                  <div className="c-text"><span dir="ltr">{contact.bandarabas_fax}</span></div>
                </li>
                <li>
                  <span className="mdi mdi-map-marker-outline"></span>
                  <div className="c-text"><span dir="ltr"><a href={"https://www.google.pl/maps/dir/" + contact.bandarabas_lat + "," + contact.bandarabas_lng + "/@" + contact.bandarabas_lat + "," + contact.bandarabas_lng} >{ReactHtmlParser(contact.bandarabas_address)}</a></span></div>
                </li>
              </ul>
            </div>




          </OwlCarousel>


        </div>


        <div>
          <ul className={`c-link ${props.class ? props.class : ''}`}>
            {this.state.socials ?
              this.state.socials.map((item, key) =>
                <li key={key}>
                  {(item.link != "" && item.link != "#" )? (<a href={item.link} target="_new"><span className={`mdi mdi-${item.name}`} /></a>) : ""}
                  
                </li>
              ) : null}
          </ul>
        </div>
      </>
    )
  }
}