import React, { Component } from "react";

// Components
import { Link } from 'react-router-dom';
// import Sidebar from '../sidebar';
import Lang from './lang';
import { LangContext } from '../../App/languageContext';
import * as Service from '../../api/common';

// svg and images
// import Logo from '../../assets/images/logo.png';
// import Grid from '../../assets/svg/grid';
// import Bars from '../../assets/svg/bars';


import './index.css';

const CurrentLocale = localStorage.getItem("language");
export default class Header extends Component {
  state = {
    openSidebar: false,
    logo: ""
  }

  open = () => {
    this.setState(
      state => ({
        openSidebar: !state.openSidebar
      })
    );
  };

  CloseSidebar = () => {
    if (this.state.openSidebar) {
      this.setState({
        openSidebar: false
      })
    }
  };

  componentWillUnmount() {
    this.setState({ openSidebar: false });
  }

  componentDidMount() {
    this.getLogo();
  }

  getLogo = async () => {
    try {
      const { data } = await Service.getLogo();
      this.setState({
        logo: data.result.logo
      })
    } catch (error) {
      console.error(error)
    }
  }

  render() {
    return (
      <>

        <div className="main-header">
          <div className="row">
            <div className="large-6 medium-6 small-6 columns columns">
              <ul className="small-menu">

                {/* زبان ها */}
                <Lang />

              </ul>
            </div>


            <div className="large-6 medium-6 small-6 columns theme-text-left">
              <Link to={`/${CurrentLocale}/home`}>
                <img src={this.state.logo} alt="logo" />
              </Link>
            </div>

          </div>
        </div>
      </>
    )
  }
}
Header.contextType = LangContext;
