import React, { Component } from "react";

// Service
import * as Service from '../../../api/common';

// Components
import Title from '../../../components/title';
import ReactHtmlParser from 'react-html-parser';
import { LangContext } from '../../../App/languageContext';
import { Link } from 'react-router-dom';
import LargeSlider from '../../../components/slider-large';
import PwaHeader from '../../../components/pwa-header';
import PwaFooter from '../../../components/pwa-footer';
import PwaNavHeader from '../../../components/pwa-nav-header/index';
import * as Yup from 'yup';
import Form from '../../../components/form';
import { Formik } from 'formik';
import loader from "../../../components/loader";
import Loading from '../../../container/site/other/loading';

const CurrentLocale = localStorage.getItem("language");

class Poll extends Component {
  state = {
    item: "",
    isLoading: true,
    words: Service.getAllWords(),
    lang: localStorage.getItem("language"),
    initialValues: {
      name_family: '',
      company: '',
      message: '',
      phone: '',
      q1: '',
      q2: '',
      q3: '',
      q4: '',
      q5: '',
      q6: '',
      q7: '',
      q8: '',
      q9: '',
      q10: '',
      a1: '',
      a2: '',
      a3: '',
      a4: '',
      a5: '',
      a6: '',
      a7: '',
      a8: '',
      a9: '',
      a10: ''
    }
    
  }

  componentDidMount() {
    this.state.q1 = this.state.words[this.state.lang]["poll_q1"];
    this.state.q2 = this.state.words[this.state.lang]["poll_q2"];
    this.state.q3 = this.state.words[this.state.lang]["poll_q3"];
    this.state.q4 = this.state.words[this.state.lang]["poll_q4"];
    this.state.q5 = this.state.words[this.state.lang]["poll_q5"];
    this.state.q6 = this.state.words[this.state.lang]["poll_q6"];
    this.state.q7 = this.state.words[this.state.lang]["poll_q7"];
    this.state.q8 = this.state.words[this.state.lang]["poll_q8"];
    this.state.q9 = this.state.words[this.state.lang]["poll_q9"];
    this.state.q10 = this.state.words[this.state.lang]["poll_q10"];
    this.setState({ isLoading: false });
  }


  handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      values.q1 = this.state.words[this.state.lang]["poll_q1"];
      values.q2 = this.state.words[this.state.lang]["poll_q2"];
      values.q3 = this.state.words[this.state.lang]["poll_q3"];
      values.q4 = this.state.words[this.state.lang]["poll_q4"];
      values.q5 = this.state.words[this.state.lang]["poll_q5"];
      values.q6 = this.state.words[this.state.lang]["poll_q6"];
      values.q7 = this.state.words[this.state.lang]["poll_q7"];
      values.q8 = this.state.words[this.state.lang]["poll_q8"];
      values.q9 = this.state.words[this.state.lang]["poll_q9"];
      values.q10 = this.state.words[this.state.lang]["poll_q10"];
      const { data } = await Service.SendPoll(values)
      alert(data.result)
      resetForm();
    } catch (error) {
      console.error(error);
    }
    setSubmitting(false);
  };

  pollQuestions = (handleChange, handleBlur, values, touched, errors) => {
    let questins = []


    for (var i = 0; i < 10; i++) {
      var qname = "q" + (i + 1);
      var aname = "a" + (i + 1);
      questins.push(
        <div className="row medium-12 large-12 small-12">
          <div className="column medium-12 large-12 small-12 no-padding no-margin">
            <input
              type="hidden"
              className="winput"
              autoComplete="off"
              required="required"
              name={qname}
              value={this.state.words[this.state.lang]["poll_" + qname]}
              onBlur={handleBlur}
              error={touched[{ aname }] && errors[{ aname }]}
              onChange={handleChange}
            />
            <label>{i + 1}) {this.state.words[this.state.lang]["poll_" + qname]}</label>
          </div>
          <div className="column medium-12 large-12 small-12 no-padding no-margin">
            <div className="column small-6">
              <input
                type="radio"
                className="winput"
                autoComplete="off"
                required="required"
                name={aname}
                value={this.state.words[this.state.lang]["poll_a1"]}
                onBlur={handleBlur}
                error={touched[{ aname }] && errors[{ aname }]}
                onChange={handleChange}
              />
              <span>
                {this.state.words[this.state.lang]["poll_a1"]}
              </span>
            </div>
            <div className="column small-6">
              <input
                type="radio"
                className="winput"
                autoComplete="off"
                required="required"
                name={aname}
                value={this.state.words[this.state.lang]["poll_a2"]}
                onBlur={handleBlur}
                error={touched[{ aname }] && errors[{ aname }]}
                onChange={handleChange}
              />
              <span> {this.state.words[this.state.lang]["poll_a2"]}</span>
            </div>

            <div className="column small-6">
              <input
                type="radio"
                className="winput"
                autoComplete="off"
                required="required"
                name={aname}
                value={this.state.words[this.state.lang]["poll_a3"]}
                onBlur={handleBlur}
                error={touched[{ aname }] && errors[{ aname }]}
                onChange={handleChange}
              />
              <span> {this.state.words[this.state.lang]["poll_a3"]}</span>

            </div>

            <div className="column small-6">
              <input
                type="radio"
                className="winput "
                autoComplete="off"
                required="required"
                name={aname}
                value={this.state.words[this.state.lang]["poll_a4"]}
                onBlur={handleBlur}
                error={touched[{ aname }] && errors[{ aname }]}
                onChange={handleChange}
              />
              <span> {this.state.words[this.state.lang]["poll_a4"]}</span>
            </div>

          </div>
          <hr />
        </div>


      )

    }

    return questins;
  }

  render() {
    const item = this.state.item;
    const loading = this.state.isLoading;
    if (loading) {
      return (
        <Loading />
      );
    } else {
      return (
        <>

          <div className="home_top_section pos-r home_section" >
            <PwaHeader client={this.props.client} />
            <LargeSlider client={this.props.client} />
            <PwaNavHeader />

            <div className="page-content page-poll">
              <div className="row columns">
                <Title icon="chart-bar">{this.state.words[this.state.lang]["poll"]}</Title>

                <Formik
                  enableReinitialize
                  initialValues={this.state.initialValues}
                  onSubmit={this.handleSubmit}
                  render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                  }) => (
                      <Form
                        onSubmit={handleSubmit}
                        loading={isSubmitting}

                      >
                        <div className="send-message-form">

                          {this.pollQuestions(handleChange, handleBlur, values, touched, errors)}

                          <textarea
                            type="text"
                            className="winput"
                            autoComplete="off"
                            required="required"
                            name="message"
                            value={values.message}
                            onBlur={handleBlur}
                            error={touched.message && errors.message}
                            onChange={handleChange}
                            rows="5"
                            placeholder={this.state.words[this.state.lang]["poll_message"]}
                          ></textarea>


                          <div className="input-name-contact">
                            <input
                              type="text"
                              className="winput"
                              autoComplete="off"
                              required="required"
                              name="name_family"
                              value={values.name_family}
                              onBlur={handleBlur}
                              error={touched.name_family && errors.name_family}
                              onChange={handleChange}
                              placeholder={this.state.words[this.state.lang]["name_family"]}
                            />
                          </div>

                          <div className="input-name-contact">
                            <input
                              type="text"
                              className="winput"
                              autoComplete="off"
                              name="company"
                              value={values.company}
                              onBlur={handleBlur}
                              error={touched.company && errors.company}
                              onChange={handleChange}
                              placeholder={this.state.words[this.state.lang]["company_name"]}
                            />
                          </div>


                          <div className="winput-contact">
                            <input
                              type="text"
                              className="winput"
                              autoComplete="off"
                              required="required"
                              name="phone"
                              value={values.phone}
                              onBlur={handleBlur}
                              error={touched.phone && errors.phone}
                              onChange={handleChange}
                              placeholder={this.state.words[this.state.lang]["phone"]}
                            />
                          </div>

                          <div className="row small-12 text-left">
                            <button className="btn-send-msg-new" type="submit">{this.state.words[this.state.lang]["send"]}</button>
                          </div>
                        </div>
                      </Form>
                    )}
                />
              </div>
            </div>
          </div>
          <PwaFooter />

        </>
      )
    }
  }
}
Poll.contextType = LangContext;
export default (Poll)