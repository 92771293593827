import React, { Component } from "react";

import './index.css';

export default class Home extends Component {
  render() {
    const props = this.props;
    return (
      <>
        <div className={`main_title ${props.dark ? 'dark' : ''}`}>
          <span className={`mdi mdi-${props.icon}`}></span>&nbsp;
          {props.children ? props.children : props.text}
        </div>
      </>
    )
  }
}