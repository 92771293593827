import React, { Component } from "react";
import { Link } from 'react-router-dom';
import * as deviceDetect from "react-device-detect";


// Components
import Sidebar from '../sidebar';
import Lang from './lang';
import { LangContext } from '../../App/languageContext';
import * as Service from '../../api/common';

// svg and images
// import Logo from '../../assets/images/logo.png';
import Grid from '../../assets/svg/grid';
import Vtour from '../../assets/svg/360';
import Bars from '../../assets/svg/bars';
import Loading from '../../container/site/other/loading';

import './index.css';
import HtmlParser from "react-html-parser";

const CurrentLocale = localStorage.getItem("language");

class Header extends Component {
  state = {
    openSidebar: false,
    isLoading: true,
    logo: "",
    config: null,
    words: null
  }

  constructor(props){
    super(props);
    this.state.words = Service.getAllWords();

  }

  open = () => {
    this.setState(
      state => ({
        openSidebar: !state.openSidebar
      })
    );
  };

  CloseSidebar = () => {
    if (this.state.openSidebar) {
      this.setState({
        openSidebar: false
      })
    }
  };

  componentWillUnmount() {
    this.setState({ openSidebar: false });
  }

  componentDidMount() {
    this.getConfig();
  };

  getConfig = async () => {
    try {
      const { data } = await Service.getConfig();
      this.setState({
        logo: data.result.logo,
        config: data.result,
        isLoading: false
      })
    } catch (error) {
      console.error(error)
    }
  };

  render() {
    if(this.state.isLoading){
      return(<Loading />);
    }else{
    return (
      <>
      {
        deviceDetect.isIOS ?
      <div className="notifbar hide">{HtmlParser(this.state.words[CurrentLocale]["pwa_help"])}</div>
        : ""
      }
      {
        (deviceDetect.isMobile || deviceDetect.isIOS || deviceDetect.isAndroid || deviceDetect.isFirefox || deviceDetect.isChrome) ? "" : <div className="notifbar usebestbrower">{HtmlParser(this.state.words[CurrentLocale]["browser_warning"])}</div>
      }
        
        <Sidebar
          close={this.CloseSidebar}
          openMenu={this.state.openSidebar}
        />
        <div className="main-header">
          <div className="row">
            <div className="large-5 medium-5 small-8 columns columns">
              <ul className="small-menu">
                {this.state.config.show_supplies_btn ?<li className="hide-for-small-only- suplises_btn"><Link to={`/${CurrentLocale}/supplies`}>{this.context.supplies}<Grid /></Link> </li>: ""}
                {this.state.config.virtual_tour_btn ?<li className="hide-for-small-only- virtual_tour_btn"><Link to={`/${CurrentLocale}/page/virtual-tour`}>{this.context.virtual_tour}<Vtour /></Link> </li>: ""}
                
                {/* زبان ها */}
                <Lang />

              </ul>
            </div>

            <div className="large-3 medium-2 columns hide-for-small-only text-center">
              <Link to={`/${CurrentLocale}/home`}>
                <img src={this.state.logo} alt="logo" />
              </Link>
            </div>

            <div className="large-5 medium-5 small-4 columns theme-text-left">
              <ul className="small-menu justify_content_flex_end">
                <li>
                  <span className="sidebar-icon" onClick={() => this.open()}>
                    {this.context.menu}<Bars />
                  </span>
                </li>
              </ul>
            </div>

          </div>
        </div>
      </>
    );
    }
  }
}
Header.contextType = LangContext;
export default Header;