import React, { Component } from "react";

// Service
import * as Service from '../../../api/common';

// Components
import Title from '../../../components/title';
import ReactHtmlParser from 'react-html-parser';
import Cat1 from './catalog1.png';
import Cat_en from './catalog2.png';
import { LangContext } from '../../../App/languageContext';

const CurrentLocale = localStorage.getItem("language");

export default class Catalog extends Component {
  state = {
    item: ""
  }

  componentDidMount() {
    this.getCatalog()
  }

  getCatalog = async () => {
    try {
      const { data } = await Service.getCatalog();
      this.setState({
        item: data.result
      })
    } catch (error) {
      console.error(error);
    }
  }
  render() {
    const item = this.state.item;
    return (
      <>
        <div className="catalog" id="catalogs">
          <div className="row columns">
            <Title text={item.title} icon="library" />
          </div>
          <div className="row catalog_row">
            <div className="large-6 medium-6 columns paragraph">
              {item.excerpt ? ReactHtmlParser(item.excerpt) : null}
            </div>
            <div className="large-6 medium-6 columns catalogs_list">
              {item.files ?
                item.files.map((file, key) =>
                  <a className="catalog_images" href={`${file.url}`} key={key} title={file.title} target="blank">
                    {(CurrentLocale === 'fa' || CurrentLocale === 'ar') ?
                      <img src={Cat1} alt={file.title} />
                      :
                      <img src={Cat_en} alt={file.title} />
                    }
                    <span>{file.title}</span>
                  </a>
                ) : null}
            </div>
          </div>
        </div>
      </>
    )
  }
}
Catalog.contextType = LangContext;
