import React, { Component } from "react";
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { NavLink } from 'react-router-dom';

// Services
import * as Service from '../../api/common';

// Components
import { LangContext } from '../../App/languageContext';
import langIcon from '../pwa-global/img/icon/language1.png';
import Swipe from '../../App/functions';


import $ from 'jquery';
import './index.css';

const CurrentLocale = localStorage.getItem("language");

class PwaHeader extends Component {
  state = {
    openSidebar: false,
    isLoading: true,
    logo: "",
    config: null,
    navMenu: null,
    isHome: false,
  }

  constructor(props) {
    super(props);
    this.state.isHome = this.props.isHome ? true : false;
  }

  setData = async () => {
    this.state.config = await Service.getConfig(true);
    this.state.logo = this.state.config.data.result.logo;
    //this.state.navMenu = await Service.getMainMenu(true);

    this.setState({ isLoading: false });
  }

  async componentWillMount() {
    await this.setData();

  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutsideMenu);
    var swiper = new Swipe(document.getElementsByTagName('body')[0]);
    if (CurrentLocale == "fa") {
      swiper.onRight(function () { document.getElementById("mySidenav").style.width = "0"; });
    } else {
      swiper.onLeft(function () { document.getElementById("mySidenav").style.width = "0"; });
    }

    swiper.run();

  }

  openNav() {
    document.getElementById("mySidenav").style.width = "250px";
  }

  closeNav(e) {
    document.getElementById("mySidenav").style.width = "0";
  }

  handleClickOutside = evt => {
    this.closeNav()
  };


  handleClickOutsideMenu(event) {

    var ev = $(event.target).parents(".sidenav");
    if (ev && ev.length <= 0) {
      if (document.getElementById("mySidenav") !== null) {
        document.getElementById("mySidenav").style.width = "0";
      }
    }
  }

  addActiveClass(e) {
    const clicked = e.target.classList;
    if (clicked.contains('false')) {
      e.target.className = 'true';
    } else {
      e.target.className = 'false';
    }
  }


  changePage(elm, page = '') {
    this.closeNav(null);
    var pageA = page.split("/");
    var pageName = pageA[pageA.length - 1].trim();
    var isInternalLink = true;
    switch (pageName) {
      case "arze":
      case "supplies":
        pageName = "supplies";
        break;
      case "contact-us":
      case "contactus":
        pageName = "contactus";
        break;

      case "news":
      case "products":
        break;

      case "":
      case "home":
        pageName = "home";
        break;

      default:
        isInternalLink = false;
        break;
    }
    var targetelm = $('.nbi-' + pageName);
    if (isInternalLink && targetelm.length) {
      $('.icon-menu-footer01').removeClass('active');
      targetelm.addClass('active');
      $('.nbi-' + pageName + ' > div').click();
    } else {
      window.location.href = page;
    }
  }


  render() {
    return (
      <>
        <nav className="navbar navbar-expand-lg navbar-dark " id="mainNav">
          <div className="container">

            {
              this.state.isHome ? (
                ""
              ) : (
                <Link to={"/"+CurrentLocale+"/home"} className="navbar-arrow-right-icon" />
                )
            }

            {/* 

            <button className="navbar-toggler collapsed" id="main" type="button" data-toggle="collapse"
              data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
              aria-label="Toggle navigation">

              <span className="navbar-toggler-icon" onClick={() => this.openNav()} > </span>

            </button>

            <div className="navbar-collapse  sidenav" id="mySidenav">
              <div className="nav-item logo-in-menu">
                <img src={this.state.logo} alt="" />
              </div>
              <a className="closebtn" onClick={() => this.closeNav()} >&times;</a>

              <ul className="side-menu navbar-nav ml-auto">
                {this.state.navMenu && this.state.navMenu.data.result.length > 0 ?
                  this.state.navMenu.data.result.map((item, key) =>
                    <li key={key}>
                      {item.childs ?
                        <span className="false" onClick={this.addActiveClass}>
                          {item.title}&nbsp;
                      <i className="mdi mdi-chevron-down "></i>
                        </span>
                        :
                        <a onClick={e => this.changePage(e.target, item.link)} to={item.link} activeClassName="is-active">{item.title}</a>
                      }
                      {(item.childs && item.childs.length > 0) ?
                        <ul className="sub_menu">
                          {item.childs.map((child, childKey) =>
                            <li key={childKey}>
                              <NavLink to={child.link} activeClassName="is-active">
                                <i className="mdi mdi-chevron-left"></i>&nbsp;
                            {child.title}
                              </NavLink>
                            </li>
                          )}
                        </ul>
                        : null}
                    </li>
                  )
                  : null}
              </ul>

            </div> */}

            <Link to="/" className="navbar-brand js-scroll-trigger">
              <img src={langIcon} alt="" />
            </Link>

          </div>
        </nav>

      </>
    );
  }
}
PwaHeader.contextType = LangContext;
export default PwaHeader;