import React, { Component } from "react";
import * as deviceDetect from "react-device-detect";
import $ from 'jquery';
import OwlCarousel from "react-owl-carousel2";

import './index.css';

import Contact from './Contact';
import ContactForm from './form';

// images
import Img1 from '../../assets/images/footer/img1.png';
import Img2 from '../../assets/images/footer/img2.png';
import Img3 from '../../assets/images/footer/img3.png';
import Img4 from '../../assets/images/footer/img4.png';
import Img5 from '../../assets/images/footer/img5.png';
import Img6 from '../../assets/images/footer/img6.png';
import androidIcon from '../../assets/images/android-icon.png';
import iosIcon from '../../assets/images/ios-icon.png';


// map
import MapImg from '../../assets/images/map.jpg';
import HtmlParser from "react-html-parser";
export default class Footer extends Component {
  
  state = {
    lang: localStorage.getItem("language"),
    options: {
      items: 5,
      dots: true,
      nav: false,
      rewind: true,
      autoHeight: false,
      rtl: true,
      mouseDrag: true,
      touchDrag: true,
      loop: true,
      autoplay: true,
      margin:15,
      //autoplayTimeout: 4000,
      //autoplayHoverPause: true,
      smartSpeed: 600,
      animateOut: 'fadeOut',
      animateIn: 'fadeIn'
    },
  }

  toggleiOSHelp(){
    var elm = $('.notifbar');
    if(elm.hasClass('hide')){
      elm.removeClass('hide');
    }else{
      elm.addClass('hide');
    }
    console.log("ddddddd");
  }


  render() {

    return (
      <>
        <div className="footer">
          <div className="row columns">
            <div className="wpartners show-for-small-only">
              <OwlCarousel ref="slider4" options={this.state.options}>
                <div className="item">
                  <a target="_blank" rel="origin" href="https://trustseal.enamad.ir/?id=189194&amp;Code=H321l7Xanm9fhv1msqqJ">
                      <img src="https://Trustseal.eNamad.ir/logo.aspx?id=189194&amp;Code=H321l7Xanm9fhv1msqqJ" alt="" style={{cursor:'pointer'}} id="H321l7Xanm9fhv1msqqJ" />
                  </a>
                </div>

                <div className="item">
                  <img src={Img1} alt="" />
                </div>
                <div className="item">
                  <img src={Img2} alt="" />
                </div>
                <div className="item">
                  <img src={Img3} alt="" />
                </div>
                <div className="item">
                  <img src={Img4} alt="" />
                </div>
                <div className="item">
                  <img src={Img5} alt="" />
                </div>
                <div className="item">
                  <img src={Img6} alt="" />
                </div>

              </OwlCarousel>
          </div>

            <ul className="partners hide-for-small-only">
              <li>
                <a target="_blank" rel="origin" href="https://trustseal.enamad.ir/?id=189194&amp;Code=H321l7Xanm9fhv1msqqJ">
                  <img src="https://Trustseal.eNamad.ir/logo.aspx?id=189194&amp;Code=H321l7Xanm9fhv1msqqJ" alt="" style={{cursor:'pointer'}} id="H321l7Xanm9fhv1msqqJ" />
                </a>
              </li >

              <li>
                <img src={Img1} alt="" />
              </li >
              <li>
                <img src={Img2} alt="" />
              </li >
              <li>
                <img src={Img3} alt="" />
              </li >
              <li>
                <img src={Img4} alt="" />
              </li >
              <li>
                <img src={Img5} alt="" />
              </li >
              <li>
                <img src={Img6} alt="" />
              </li >
             
            </ul>
          </div>
          <div className="row">
            <div className="large-5 medium-5 columns">
              <ContactForm />
            </div>
            <div className="large-3 medium-3 columns">
              <Contact />
            </div>
            <div className="large-4 medium-4 columns">
              <img src={MapImg} alt="" />
            </div>
          </div>
        </div>
        { !deviceDetect.isIOS ?
          <div className="dlappDiv apkIco" title="JeyOil Android App">
            <a href="/dl"><img src={androidIcon} /></a>
          </div>
          :
          <div className="dlappDiv iosIco" onClick={() => { this.toggleiOSHelp() }} title="Web App(PWA) Help">
            <img src={iosIcon} />
          </div>
        }

      </>
    )
  }
}