import React, { Component } from "react";

// Service
import * as Service from '../../../api/common';

// Components
import * as Yup from 'yup';
import Form from '../../../components/form';
import Icon from './contact';
import { Formik } from 'formik';
// import Catalog from '../home/catalog';
import { Link } from "react-router-dom";
import Title from '../../../components/title';
import Layout from "../../../components/inner-layout";
import { LangContext } from '../../../App/languageContext';

import CInfo from '../../../components/footer/Contact';

const CurrentLocale = localStorage.getItem("language");

class Contact extends Component {
  state = {
    initialValues: {
      name: '',
      email: '',
      message: '',
      type: 'message'
    }
  }

  constructor(props){
    super(props);
    if(props.type !== null){
      this.state.initialValues.type = props.type;
    }
  }

  handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      let name = values.name,
        email = values.email,
        message = values.message,
        type = this.state.initialValues.type;
      const { data } = await Service.SendForm(name, email, message,type)
      alert(data.result)
      resetForm();
    } catch (error) {
      console.error(error);
    }
    setSubmitting(false);
  };
  // ااعتبار سنجی فرم
  ValidationSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().required(),
    message: Yup.string().required(),
  });

  render() {

    return (
      <>
        {/* مسیر سایت */}
        <div className="bread_crumb">
          <div className="row text-center content">
            <div className="page_title">
              <Icon />
              <span>{this.state.initialValues.type == 'shekayat' ? (this.context.sendComplaint) : (this.context.contact)}</span>
            </div>
            <ul className="br-menu">
              <li><Link to={`/${CurrentLocale}/home`}><span className="mdi mdi-home-outline"></span></Link></li>
              <li><span>
              {
              this.state.initialValues.type == 'shekayat' ? (this.context.sendComplaint) : (this.context.contact)
              }</span></li>
            </ul>
          </div>
        </div>
        {/*  */}
        <div className="page-content">
          <div className="row columns">
            <Title icon="gmail">
              {this.state.initialValues.type == 'shekayat' ? (this.context.sendComplaint) : (this.context.contact)}
            </Title>
            <div className="comment_section">
              <div className="row">
                <div className="large-5 medium-6 columns comment_send">
                  <Formik
                    enableReinitialize
                    initialValues={this.state.initialValues}
                    validationSchema={this.ValidationSchema}
                    onSubmit={this.handleSubmit}
                    render={({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue
                    }) => (
                        <Form
                          onSubmit={handleSubmit}
                          loading={isSubmitting}
                          text={this.context.SendInformation}
                        >
                          <div className="form-input">

                            <input
                              type="text"
                              autoComplete="off"
                              placeholder={
                                this.context.name + ' ' +
                                this.context.and + ' ' +
                                this.context.lastName
                              }
                              required="required"
                              name="name"
                              value={values.name}
                              onBlur={handleBlur}
                              error={touched.name && errors.name}
                              onChange={handleChange}
                            />
                            <span className="mdi mdi-account-outline"></span>
                          </div>
                          <div className="form-input">
                            <input
                              type="Email"
                              autoComplete="off"
                              placeholder={this.context.Email}
                              required="required"
                              name="email"
                              value={values.email}
                              onBlur={handleBlur}
                              error={touched.email && errors.email}
                              onChange={handleChange}
                            />
                            <span className="mdi mdi-email-outline"></span>
                          </div>

                          <div className="form-textarea">
                            <textarea
                              autoComplete="off"
                              placeholder={
                                this.state.initialValues.type == 'shekayat' ? (this.context.complaintMessage) : (this.context.messageText)
                              }
                              required="required"
                              name="message"
                              value={values.message}
                              onBlur={handleBlur}
                              error={touched.message && errors.message}
                              onChange={handleChange}
                            ></textarea>
                            <span className="mdi mdi-email-outline"></span>
                          </div>
                          <button className="button send" type="submit">
                            {this.state.initialValues.type == 'shekayat' ? (this.context.sendComplaint) : (this.context.Sendmessage)}
                          </button>
                        </Form>
                      )}
                  />
                </div>
                <div className="large-7 medium-6 columns">

                  <CInfo className="dark" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Catalog /> */}

      </>
    )
  }
}
Contact.contextType = LangContext;
export default Layout(Contact);