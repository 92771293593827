import React, { Component } from "react";

// Service
import * as Service from '../../../api/common';

// Components
import BlogCard from './item-in-list';
import { Link } from 'react-router-dom';
import Icon4 from "../../../assets/svg/news";
import Layout from '../../../components/inner-layout';
import Pagination from '../../../components/pagination';
import { LangContext } from '../../../App/languageContext';

const CurrentLocale = localStorage.getItem("language");

class SuppliesList extends Component {
  state = {
    allDataList: [],
    currentBlogs: [],
    currentPage: null,
    totalPages: null
  };

  componentDidMount() {
    this.getList()
    const allDataList = this.state.allDataList;
    this.setState({ allDataList });
  }

  getList = async () => {
    try {
      const { data } = await Service.getRecentSupplies(0, 1000);
      this.setState({
        allDataList: data.result
      })
    } catch (error) {
      console.error(error);
    }
  }

  onPageChanged = data => {
    const { allDataList } = this.state;
    const { currentPage, totalPages, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    const currentBlogs = allDataList.slice(offset, offset + pageLimit);
    this.setState({ currentPage, currentBlogs, totalPages });
  };

  render() {
    Service.setPageTitle();
    const {
      allDataList,
      currentBlogs,
    } = this.state;
    const totalCountries = allDataList.length;

    if (totalCountries === 0) return null;

    return (
      <>
        {/* مسیر سایت */}
        <div className="bread_crumb">
          <div className="row text-center content">
            <div className="page_title">
              <Icon4 />
              <span>{this.context.supplies}</span>
            </div>
            <ul className="br-menu">
              <li><Link to={`/${CurrentLocale}/home`}><span className="mdi mdi-home-outline"></span></Link></li>
              <li><span>{this.context.supplies}</span></li>
            </ul>
          </div>
        </div>

        <div className="page-content">
          <div className="row">
            <div className="white-panel">
              {/* لیست بلاگ */}
              <div className="row columns">
                {currentBlogs ?
                  currentBlogs.map((item, key) => (
                    <BlogCard key={key} data={item} />
                  ))
                  : null}
              </div>
              <div className="row columns text-center">
                <Pagination
                  totalRecords={totalCountries}
                  pageLimit={5}
                  pageNeighbours={1}
                  onPageChanged={this.onPageChanged}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
SuppliesList.contextType = LangContext;
export default Layout(SuppliesList)