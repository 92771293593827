import React, { Component } from "react";
import { Link, Redirect } from 'react-router-dom';

// const CurrentLocale = localStorage.getItem("language");

export default class LiLang extends Component {
    state = {
        isActive: "",
        redirectToHome: false,
        newLocale: '',
        newLang: ""
    };

    componentDidMount() {
        this.activer();
    }

    activer() {
        if (!localStorage.getItem("language")) {
            localStorage.setItem("language", 'fa');
            window.location.reload();
        }
        const active = localStorage.getItem("language");
        this.setState({ isActive: active });
    }

    setLang(){
        var lang  = localStorage.getItem("language");
        let currenURL = window.location.href.replace('https://','').replace('http://','').split('/');
        if(typeof currenURL[1] !== 'undefined' && currenURL[1].length == 2) {
          lang = currenURL[1].toLowerCase();
        }else{
          if(typeof lang === 'undefined' || lang === null || lang.length !== 2){
            lang = 'fa';
          }
        }
        localStorage.setItem("language", lang);
      }

      
    changelang = async (e, lang) => {
        localStorage.setItem("language", lang);
        this.activer();
        e.className = "is-active";
        // window.location.href = "/";
        // window.location.reload()
        this.setLang();
        const CRLang = localStorage.getItem("language");
        const html = document.querySelector("html");
        if (CRLang === "en" || CRLang === "in" || CRLang === "cn") {
            html.setAttribute("dir", "ltr");
        } else {
            html.setAttribute("dir", "rtl");
        }
        html.setAttribute("lang", CRLang);
        await this.setState({ redirectToHome: true, newLang: lang });
    };

    render() {
        if (this.state.redirectToHome) {
            return (<Redirect to={`/${this.state.newLang}/home`} />)
        }
        return (
            <>
                <li
                    onClick={e => this.changelang(e.target, 'fa')}
                    className={this.state.isActive === 'fa' ? "is-active" : ""}
                >
                    <Link to={`/fa/home`}><span>فارسی</span></Link>
                </li>
                <li
                    onClick={e => this.changelang(e.target, 'en')}
                    className={this.state.isActive === 'en' ? "is-active" : ""}
                >
                    <Link to={`/en/home`}><span>English</span></Link>
                </li>
                
            </>
        );
    }
}
