import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import $ from 'jquery';
import { browserHistory } from 'react-router';

// Services
// import { APIClient } from '../api/_api';
// import getAllWords from "../api/getAllWord";
import * as Service from '../api/common';

// Components
import Render from './home';
import ScrollToTop from "./scrollTop";
import { LangContext } from "./languageContext";


// site styles
import "../assets/css/foundation.css";
import "../assets/css/app.css";
import "../container/mobile/mobile.css";

//
// import Spinner from "../assets/images/spinner.gif";
import Loading from '../container/site/other/loading';


// ===========================================
// Site Routers
// ===========================================
import Home from "../container/site/home";
import Home_otherLangs from "../container/site/home_otherLangs";
import BlogList from "../container/site/blog/list";
import BlogItem from "../container/site/blog/item";
import ProductList from "../container/site/product-list";
import Product from "../container/site/product";
import ProjectList from "../container/site/project-list";
import Project from "../container/site/project";
import AboutCompany from "../container/site/about-company/index";
//import Achievements from "../container/site/about-company/achievements";
import Managers from "../container/site/about-company/managers";
import OrganizationalChart from "../container/site/about-company/organizational-chart";
import Rules from "../container/site/about-company/rules";
import SubsidiaryCompanies from "../container/site/about-company/subsidiary-companies";
import Trailer from "../container/site/trailer";
import UnitCatsList from "../container/site/units-list";
import UnitsList from "../container/site/units/list";
import UnitItem from "../container/site/units/item";
import SuppliesList from "../container/site/supplie/list";
import SuppliesItem from "../container/site/supplie/item";
import GalleryList from "../container/site/gallery/list";
import GalleryItem from "../container/site/gallery/item";
import Achievements from "../container/site/achievements";
import Contact from "../container/site/contact";
import Page from "../container/site/pages";
import PollPage from "../container/site/poll/index";
import OrderPage from "../container/site/order/index";
import Test from "../container/test";

// 404
// import NotFoundPage from './NotFoundPage';

// ==================================
// Mobile routers 
// ==================================
// import SelectLang from '../container/mobile/select-lang';
// import MobileHome from '../container/mobile/home';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ss: "",
      loading: true,
      currentLang: "",
      isMainLangs: false,
      client: localStorage.getItem("client"),
      isPWA: localStorage.getItem("isPWA")
      // currentAppLocale: AppLocale['fa']
    }

  }
  // isPWA = () =>(
  //   window.matchMedia('(display-mode: standalone)').matches) || 1==1 || (window.navigator.standalone) || document.referrer.includes('android-app://'
  // );


  // لودینگ سایت
  loader() {
    return new Promise(resolve => setTimeout(() => resolve(), 1500));
  }



  // allWords = async () => {
  //   const { data } = await Service.getConfig();
  //   let allWords = {};
  //   let currentLAng = localStorage.getItem("language");

  //   data.result.translations.forEach(word => {
  //     if (currentLAng === "en") {
  //       allWords[word.key] = word.en;
  //     } else if (currentLAng === "fa") {
  //       allWords[word.key] = word.fa;
  //     } else if (currentLAng === "ar") {
  //       allWords[word.key] = word.ar;
  //     } else if (currentLAng === "in") {
  //       allWords[word.key] = word.in;
  //     } else {
  //       allWords[word.key] = word.cn;
  //     }
  //   });

  //   if (!localStorage.getItem("language")) {
  //     localStorage.setItem("language", "fa")
  //   }
  //   this.setState({ allWords });
  //   // افزودن عنوان به صفحه اچ تی امل
  //   document.querySelector("title").innerHTML = allWords.SiteTitle
  //     ? allWords.SiteTitle
  //     : "شرکت پالایش نفت جی ، بزرگترین واحد تولید کننده قیر در خاورميانه";

  //   //await localStorage.setItem("Words", JSON.stringify(this.state.allWords));
  //   if (localStorage.getItem("Words") !== JSON.stringify(allWords)) {

  //     // setTimeout(() => {
  //     //   window.location.reload();
  //     // }, 500);
  //   }

  // };


  componentDidMount = async () => {
    await localStorage.setItem('token', '6fe6c750c23ae95dcf73de5dbfda2fbe');
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", "fa")
    }

    // await this.allWords();
    // await setAuthToken(localStorage.getItem('token'));
    //this.loader().then(() => this.setState({ loading: false }));

    this.state.currentLang = localStorage.getItem("language");
    if (this.state.currentLang == 'fa' || this.state.currentLang == 'en') {
      this.setState({ isMainLangs: true });
    }
    $(document).ready(e => {
      this.setState({ loading: false });
    });

  }


  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {

    }
    Service.setPageTitle();

  }



  render() {

    window.addEventListener("hashchange", function () {
      Service.setPageTitle();
    }, false);

    let allWords = Service.getAllWords();

    if (this.state.loading || allWords == null ) {
      return (<Loading />);
    } else {
      

      return (

        <>
          <LangContext.Provider value={allWords[this.state.currentLang]}>

            <Router>
              <ScrollToTop>
                {/* {isMobile ? (
                <Switch>
                  <Route exact path={`/`} component={Render} />
                
              <Route exact={true} path={'/'}>
                <SelectLang />
              </Route>
              <Route exact={true} path={'/home-page'}>
                <MobileHome />
              </Route>
                </Switch>
            ) : ( */}


                {

                  localStorage.getItem("client") == "pwa" ? (
                    <Switch>

                    </Switch>
                  ) : (
                      <Switch>
                        <Route exact path={`/`} component={(props) => <Render {...props} client={this.props.client} />} />
                        <Route exact path={`/:lang`} component={(props) => <Render {...props} client={this.props.client} />} />
                        <Route exact path={`/:lang/home`} component={(props) =>
                          this.state.isMainLangs ? <Home {...props} client={this.props.client} config={this.props.config}  /> : <Home_otherLangs {...props} client={this.props.client} config={this.props.config} />
                        } />
                        <Route exact path={`/:lang/about-our-company`} component={(props) => <AboutCompany {...props} client={this.props.client} />} />
                        {/* <Route exact path={`/:lang/achievements`} component={(props) => <Achievements {...props} client={this.props.client} />} /> */}
                        <Route exact path={`/:lang/managers`} component={(props) => <Managers {...props} client={this.props.client} />} />
                        <Route exact path={`/:lang/rules`} component={(props) => <Rules {...props} client={this.props.client} />} />
                        <Route exact path={`/:lang/organizational-chart`} component={(props) => <OrganizationalChart {...props} client={this.props.client} />} />
                        <Route exact path={`/:lang/subsidiary-companies`} component={(props) => <SubsidiaryCompanies {...props} client={this.props.client} />} />
                        <Route exact path={`/:lang/trailer`} component={(props) => <Trailer {...props} client={this.props.client} />} />
                        <Route exact path={`/:lang/company-unit-cats`} component={(props) => <UnitCatsList {...props} client={this.props.client} />} />
                        <Route exact path={`/:lang/company-unit-cats/:id`} component={(props) => <UnitsList {...props} client={this.props.client} />} />
                        <Route exact path={`/:lang/company-unit-cats/:id/:title`} component={(props) => <UnitsList {...props} client={this.props.client} />} />
                        <Route exact path={`/:lang/company-units`} component={(props) => <UnitsList {...props} client={this.props.client} />} />
                        <Route exact path={`/:lang/company-units/:id/:title`} component={(props) => <UnitItem {...props} client={this.props.client} />} />
                        <Route exact path={`/:lang/products`} component={(props) => <ProductList {...props} client={this.props.client} />} />
                        <Route exact path={`/:lang/products/:id/:title`} component={(props) => <Product {...props} client={this.props.client} />} />
                        <Route exact path={`/:lang/projects`} component={(props) => <ProjectList {...props} client={this.props.client} />} />
                        <Route exact path={`/:lang/projects/:id/:title`} component={(props) => <Project {...props} client={this.props.client} />} />
                        <Route exact path={`/:lang/news`} component={(props) => <BlogList {...props} client={this.props.client} />} />
                        <Route exact path={`/:lang/news/:id`} component={(props) => <BlogItem {...props} client={this.props.client} />} />
                        <Route exact path={`/:lang/news/:id/:title`} component={(props) => <BlogItem {...props} client={this.props.client} />} />
                        <Route exact path={`/:lang/news-cats/:id`} component={(props) => <BlogList {...props} client={this.props.client} />} />
                        <Route exact path={`/:lang/news-cats/:id/:title`} component={(props) => <BlogList {...props} client={this.props.client} />} />
                        <Route exact path={`/:lang/supplies`} component={(props) => <SuppliesList {...props} client={this.props.client} />} />
                        <Route exact path={`/:lang/supplies/:id`} component={(props) => <SuppliesItem {...props} client={this.props.client} />} />
                        <Route exact path={`/:lang/gallery`} component={(props) => <GalleryList {...props} client={this.props.client} />} />
                        <Route exact path={`/:lang/gallery/:id`} component={(props) => <GalleryItem {...props} client={this.props.client} />} />
                        <Route exact path={`/:lang/achievements`} component={(props) => <Achievements {...props} client={this.props.client} />} />
                        <Route exact path={`/:lang/contact-us`} component={(props) => <Contact {...props} type="message" client={this.props.client} />} />
                        <Route exact path={`/:lang/complaint`} component={(props) => <Contact {...props} type="shekayat" client={this.props.client} />} />
                        <Route exact path={`/:lang/page/:name`} component={(props) => <Page {...props} client={this.props.client} />} />
                        <Route exact path={`/:lang/poll`} component={(props) => <PollPage {...props} client={this.props.client} />} />
                        <Route exact path={`/:lang/order`} component={(props) => <OrderPage {...props} client={this.props.client} />} />
                        {/* <Route exact path={`/test`} component={(props) => <Test {...props} client={this.props.client} />} /> */}
                        <Route path="*" component={(props) => <Home {...props} client={this.props.client} />} />

                      </Switch>
                    )

                }






                {/* <Route exact path={`/fa/home`} component={() => <Home />} />
                <Route exact path={`/en/home`} component={() => <Home />} />
                <Route exact path={`/ar/home`} component={() => <Home_otherLangs />} />
                <Route exact path={`/in/home`} component={() => <Home_otherLangs />} />
                <Route exact path={`/cn/home`} component={() => <Home_otherLangs />} /> */}

                {/* <Route exact path={`/enabout-our-company`} component={() => <AboutCompany
                <Route exact path={`/arabout-our-company`} component={() => <AboutCompany
                <Route exact path={`/inabout-our-company`} component={() => <AboutCompany
                <Route exact path={`/cnabout-our-company`} component={() => <AboutCompany */}


                {/* <Route exact path={`/en/trailer`} component={() => <Trailer />} /> */}
                {/* <Route exact path={`/ar/trailer`} component={() => <Trailer
                <Route exact path={`/in/trailer`} component={() => <Trailer
                <Route exact path={`/cn/trailer`} component={() => <Trailer */}

                {/* <Route exact path={`/en/company-units`} component={() => <UnitsList />} /> */}
                {/*<Route exact path={`/ar/company-units`} component={() => <UnitsList
                <Route exact path={`/in/company-units`} component={() => <UnitsList
                <Route exact path={`/cn/company-units`} component={() => <UnitsList */}

                {/* <Route exact path={`/en/company-units/:id/:title`} component={() => <UnitItem />} /> */}
                {/*<Route exact path={`/ar/company-units/:id/:title`} component={() => <UnitItem
                <Route exact path={`/in/company-units/:id/:title`} component={() => <UnitItem
                <Route exact path={`/cn/company-units/:id/:title`} component={() => <UnitItem */}

                {/* <Route exact path={`/en/products`} component={() => <ProductList />} /> */}
                {/*<Route exact path={`/ar/products`} component={() => <ProductList
                <Route exact path={`/in/products`} component={() => <ProductList
                <Route exact path={`/cn/products`} component={() => <ProductList */}

                {/* <Route exact path={`/en/products/:id/:title`} component={() => <Product />} /> */}
                {/*<Route exact path={`/ar/products/:id/:title`} component={() => <Product
                <Route exact path={`/in/products/:id/:title`} component={() => <Product
                <Route exact path={`/cn/products/:id/:title`} component={() => <Product */}

                {/* <Route exact path={`/en/news`} component={() => <BlogList />} /> */}
                {/*<Route exact path={`/ar/news`} component={() => <BlogList
                <Route exact path={`/in/news`} component={() => <BlogList
                <Route exact path={`/cn/news`} component={() => <BlogList */}

                {/* <Route exact path={`/en/news/:id/:title`} component={() => <BlogItem />} /> */}
                {/*<Route exact path={`/ar/news/:id/:title`} component={() => <BlogItem
                <Route exact path={`/in/news/:id/:title`} component={() => <BlogItem
                <Route exact path={`/cn/news/:id/:title`} component={() => <BlogItem */}

                {/* <Route exact path={`/en/supplies`} component={() => <SuppliesList />} /> */}
                {/*<Route exact path={`/ar/supplies`} component={() => <SuppliesList
                <Route exact path={`/in/supplies`} component={() => <SuppliesList
                <Route exact path={`/cn/supplies`} component={() => <SuppliesList */}

                {/* <Route exact path={`/en/supplies/:id`} component={() => <SuppliesItem />} /> */}
                {/*<Route exact path={`/ar/supplies/:id`} component={() => <SuppliesItem
                <Route exact path={`/in/supplies/:id`} component={() => <SuppliesItem
                <Route exact path={`/cn/supplies/:id`} component={() => <SuppliesItem */}

                {/* <Route exact path={`/en/gallery`} component={() => <GalleryList />} /> */}
                {/*<Route exact path={`/ar/gallery`} component={() => <GalleryList
                <Route exact path={`/in/gallery`} component={() => <GalleryList
                <Route exact path={`/cn/gallery`} component={() => <GalleryList */}

                {/* <Route exact path={`/en/gallery/:id`} component={() => <GalleryItem />} /> */}
                {/*<Route exact path={`/ar/gallery/:id`} component={() => <GalleryItem
                <Route exact path={`/in/gallery/:id`} component={() => <GalleryItem
                <Route exact path={`/cn/gallery/:id`} component={() => <GalleryItem */}

                {/* <Route exact path={`/en/contact-us`} component={() => <Contact />} /> */}
                {/*<Route exact path={`/ar/contact-us`} component={() => <Contact
                <Route exact path={`/in/contact-us`} component={() => <Contact
                <Route exact path={`/cn/contact-us`} component={() => <Contact */}

                {/* <Route exact path={`/en/page/:name`} component={() => <Page />} /> */}

                {/* 404 not found */}
                {/* <Route path="*" component={() => <NotFoundPage />} /> */}

                {/* )} */}
              </ScrollToTop>
            </Router>
          </LangContext.Provider>
        </>
      );
    }
  }
}
export default App;
