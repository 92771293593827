import React, { Component } from "react";
// import Redirent
// import App from './App';
import { Redirect } from 'react-router-dom';

class AppRender extends Component {
	constructor(props) {
		super(props);
		const CurrentLocale = (localStorage.getItem("language") ? localStorage.getItem("language") : 'fa')
		this.state = {
			lang: CurrentLocale
			// routes: [
			// 	{
			// 		route: `/${CurrentLocale}`,
			// 		exact: false,
			// 		component: App
			// 	}
			// ]
		};
	}

	render() {
		return <Redirect to={`/${this.state.lang}/home`} />;
	}
}
export default AppRender;