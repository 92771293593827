import React, { Component } from "react";
import { Link, Redirect } from 'react-router-dom';

// const CurrentLocale = localStorage.getItem("language");

export default class LiLang extends Component {
    state = {
        isActive: "",
        redirectToHome: false,
        newLocale: '',
        newLang: ""
    };

    componentDidMount() {
        this.activer();
    }

    activer() {
        if (!localStorage.getItem("language")) {
            localStorage.setItem("language", 'fa');
            window.location.reload();
        }
        const active = localStorage.getItem("language");
        this.setState({ isActive: active });
    }
    changelang = async (e, lang) => {
        localStorage.setItem("language", lang);
        this.activer();
        e.className = "is-active";
        // window.location.href = "/";
        await this.setState({ redirectToHome: true, newLang: lang });
        window.location.reload()
        // تعیین راستچین یا چپ چین بودن سایت
        // const CRLang = localStorage.getItem("language");
        // if (CRLang === "en" || CRLang === "in" || CRLang === "cn") {
        //     const html = document.querySelector("html");
        //     this.setState({redirectToHome:true})
        //     // html.setAttribute("dir", "ltr");
        // } else {
        //     const html = document.querySelector("html");

        //     // html.setAttribute("dir", "rtl");
        // }
    };

    render() {
        if (this.state.redirectToHome) {
            return (<Redirect to={`/${this.state.newLang}/home`} />)
        }
        return (
            <>
                {/* <li
                    onClick={e => this.changelang(e.target, 'fa')}
                    className={this.state.isActive === 'fa' ? "is-active" : ""}
                >
                    <span>فارسی</span>
                </li>
                <li
                    onClick={e => this.changelang(e.target, 'en')}
                    className={this.state.isActive === 'en' ? "is-active" : ""}
                >
                    <span>English</span>
                </li>
                <li
                    onClick={e => this.changelang(e.target, 'ar')}
                    className={this.state.isActive === 'ar' ? "is-active" : ""}
                >
                    <span>العربیة</span>
                </li>
                <li
                    onClick={e => this.changelang(e.target, 'in')}
                    className={this.state.isActive === 'in' ? "is-active" : ""}
                >
                    <span>हिन्दी</span>
                </li>
                <li
                    onClick={e => this.changelang(e.target, 'cn')}
                    className={this.state.isActive === 'cn' ? "is-active" : ""}
                >
                    <span>中国</span>
                </li> */}
                <li
                    //onClick={e => this.changelang(e.target, 'fa')}
                    className={this.state.isActive === 'fa' ? "is-active" : ""}
                >
                    <a href={`/fa/home`}><span>فارسی</span></a>
                </li>
                <li
                    //onClick={e => this.changelang(e.target, 'en')}
                    className={this.state.isActive === 'en' ? "is-active" : ""}
                >
                    <a href={`/en/home`}><span>English</span></a>
                </li>
                <li
                    //onClick={e => this.changelang(e.target, 'ar')}
                    className={this.state.isActive === 'ar' ? "is-active" : ""}
                >
                    <a href={`/ar/home`}><span>العربیة</span></a>
                </li>
                <li
                    //onClick={e => this.changelang(e.target, 'in')}
                    className={this.state.isActive === 'in' ? "is-active" : ""}
                >
                    <a href={`/in/home`}><span>हिन्दी</span></a>
                </li>
                <li
                    //onClick={e => this.changelang(e.target, 'cn')}
                    className={this.state.isActive === 'cn' ? "is-active" : ""}
                >
                    <a href={`/cn/home`}><span>中国</span></a>
                </li>
            </>
        );
    }
}
