
import 'core-js/es/map';
import 'core-js/es/set';
import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import 'raf/polyfill';
import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import * as deviceDetect from "react-device-detect";

import * as serviceWorker from './serviceWorker';
import * as Service from './api/common';
import crypto from 'crypto';


// import App from './App/App';
// import Swipe from './App/functions';

import './index.css';
//import Loading from './container/pwa/other/loading';
import Loading from './container/site/other/loading';

// import crypto from 'crypto';

// var message = "{agent}@MJ@{time}";
// var key = "QrzMwvH7zmVn5Kzu";
// var iv = "Demo345678123456";
// var algoritm = 'aes-128-cbc';

// function AES_encrypt(plainText) {
//     const cipher = crypto.createCipheriv(algoritm, key, iv);
//     let encrypted = cipher.update(plainText, 'utf8', 'base64')
//     encrypted += cipher.final('base64');
//     return encrypted;
// };
// function AES_decrypt (messagebase64) {
//     const decipher = crypto.createDecipheriv(algoritm, key, iv);
//     let decrypted = decipher.update(messagebase64, 'base64');
//     decrypted += decipher.final();
//     return decrypted;
// }


// var cipherText = AES_encrypt(message);
// var decryptedCipherText = AES_decrypt(cipherText);
// console.log('Plaintext: ' + message);
// console.log('Ciphertext: ' + cipherText);
// console.log('Decoded Ciphertext: ' + decryptedCipherText);
var globalconfig;

async function getGlobalData() {
    try {
        const {slider} = await Service.getSlider();
        const {data} = await Service.getConfig();
        globalconfig = data.result;
        let allWords = {};
        allWords["fa"] = {};
        allWords["en"] = {};
        allWords["ar"] = {};
        allWords["in"] = {};
        allWords["cn"] = {};
        let currentLAng = localStorage.getItem("language");
        if(typeof data.result !== "undefined"){
            data.result.translations.forEach(word => {
                if (word.fa !== null) {
                    allWords["fa"][word.key] = word.fa;
                }
                if (word.en !== null) {
                    allWords["en"][word.key] = word.en;
                }
                if (word.ar !== null) {
                    allWords["ar"][word.key] = word.ar;
                }
                if (word.in !== null) {
                    allWords["in"][word.key] = word.in;
                }
                if (word.cn !== null) {
                    allWords["cn"][word.key] = word.cn;
                }
            });
            localStorage.setItem("Words", JSON.stringify(allWords));
        }
        Service.setPageTitle();
    } catch (error) {
        console.error(error);
    }
}
async function getDescktopData() {
    try {
        const {config} = await Service.getConfig();
        //await Service.getSlider();
    } catch (error) {
        console.error(error);
    }
}

async function getMobileData() {
    try {
        //await Service.getMobileConfig();
        await Service.getMainMenu();
        await Service.getMobileHome();
        await Service.getSupplies();
        await Service.getProducts();
        await Service.getNews();
    } catch (error) {
        console.error(error);
    }
}

function update(AppTypeVersina, NewAppVer) {
    var appVer = localStorage.getItem(AppTypeVersina) ? localStorage.getItem(AppTypeVersina) : '';
    console.log("AppTypeVersina: "+AppTypeVersina);
    console.log("NewAppVer: "+NewAppVer);
    console.log("appVer: "+appVer);
    localStorage.setItem(AppTypeVersina, NewAppVer);
    if (appVer != '' && appVer != NewAppVer) {
        console.log('Updating...');
        localStorage.clear();
        localStorage.setItem('doClearCache', true);
        try {
            
            serviceWorker.unregister();
            //alert("Please Refresh this page for update");
            console.log('Updated to New Version!');
            // window.location.reload(true);
            // window.location.href = '/';
        } catch (error) {
            console.log(error);
        }
        
        window.location.reload();

    }


}


Service.setLang();
var client = Service.webType();
var isPWA = false;
if (client == 'pwa') {
    isPWA = true;
}


(async () => {

    ReactDOM.render(
        <Loading />
        , document.getElementById('root')
    );

    await getGlobalData();
    localStorage.setItem('doClearCache', false);
    var getDataBackInterval = setInterval(async function () {
        await getMobileData();
    }, 60 * 1000);

    var AppTypeVersina = 'site_version';
    var NewAppVer = '1.1.19';

    if (isPWA) {
        AppTypeVersina = 'pwa_version';
        NewAppVer = '1.1.19';
        update(AppTypeVersina, NewAppVer);
        getMobileData();
        var AppPwa = require('./App/AppPwa').default;
        ReactDOM.render(
            <AppPwa isPWA={isPWA} client={client} config={globalconfig} />
            , document.getElementById('root')
        );
    } else {
        update(AppTypeVersina, NewAppVer);
        getDescktopData();
        var App = require('./App/App').default;
        ReactDOM.render(
            <App isPWA={isPWA} client={client} config={globalconfig} />
            , document.getElementById('root')
        );
    }



    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.register();
})();