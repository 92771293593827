import React, { Component } from "react";

class Test extends Component {
  state = {
    status: false,
    data: [],
    interval: null
  }

  getRandomInt(max = 100) {
    var min = 0;
    return min + Math.floor(Math.random() * (max - min + 1));
  }

  checkStatus = () => {
    this.setState((state, props) => ({
      status: !this.state.status
    }));
  }
  // ================================================================
  componentDidUpdate() {
    if (this.state.status) {
      this.randomRating(true);
    } else {
      this.randomRating(false);
    }
  }
  // ================================================================
  randomRating = (go) => {
    if (go) {
      let randomTime = this.getRandomInt(7);
      let randomRate = (Math.random() * (10)).toFixed(1);
      let randomItem = this.getRandomInt(this.state.data.length);
      if (randomTime <= 0) {
        randomTime = 1;
      }
      randomTime = randomTime * 1000;

      console.log("Started... (" + randomTime + ")");
      this.state.interval = setInterval(() => {
        if (typeof this.state.data[randomItem] === "undefined") {
          this.state.data[randomItem] = {
            rating: 0,
            movie: 0
          };
        }
        this.state.data[randomItem].rating = randomRate;
        //this.getSorting(this.state.data);
        console.log(this.state.data[randomItem]);

      },
        randomTime);

    } else {
      if (this.state.interval !== null) {
        clearInterval(this.state.interval);
        console.log("Stoped!");
      }

    }


  }

  render() {
    return (
      <>
        <button onClick={() => this.checkStatus()} > GO </button>
      </>
    )
  }
}
export default (Test)